import ArrowRightIcon from "assets/icons/arrowrigtwhite.svg";
import DotsIcon from "assets/icons/dotsgreen.svg";
import { Link } from "react-router-dom";
import "./CTA.css";

const CTA: React.FC = () => {
    return (
        <div className="ctaContainer">
            <div className="ctaBox ctaBox1">
                <img src={DotsIcon} alt="Icon" className="ctaBox1Icon"/>
                <div className="ctaBox1Text">
                    VSorts™ <span className="textGreen">Changing the world one vignette at a time™.</span>
                </div>
            </div>
            <Link
                to={
                    "https://us21.list-manage.com/contact-form?u=8a18a10e991aafb1bb8895f4d&form_id=46b5cc8b8f802ca81fae6530065d11f6"
                }
                target="_blank"
                className="ctaBox ctaBox2"
            >
                <h1 className="ctaBox2Text">Request a meeting</h1>
                <img src={ArrowRightIcon} alt="Arrow" />
            </Link>
        </div>
    );
};

export default CTA;
