import { CurrentUserSelector } from "app/slices/UserSlice";
import CircularLoadingProgress from "components/CircularProgress";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentUserHasAdminRole, currentUserHasUserRole } from "utils/auth.utils";

const DashboardRedirectPage = () => {
    const navigate = useNavigate();
    const currentUser = useSelector(CurrentUserSelector);

    useEffect(() => {
        if (currentUser) {
            const roles = currentUser[`${window.location.origin}/roles`];
            // const roles = currentUser[`https://stem4real.vsorts.app/roles`];

            if (currentUserHasAdminRole(roles)) {
                navigate("/admin");
            } else if (currentUserHasUserRole(roles)) {
                navigate("/user/dashboard");
            } else {
                navigate("/");
            }
        }
    });

    return <CircularLoadingProgress loadingText={"loading...."} />;
};

export default DashboardRedirectPage;
