import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Box, CssBaseline, IconButton, Typography } from "@mui/material";
import EyeIcon from "assets/icons/eye.svg";
import TrashIcon from "assets/icons/trash-red.svg";
import Header from "components/Dashboard/Header";
import { Button } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import { useRef } from "react";
import { Outlet } from "react-router-dom";
import styles from "./styles";

function CreateSurveyLayout() {
    const childRef = useRef<any>();

    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.save === "function") {
                console.log("saved");
                childRef.current.save();
            }
        }
    };

    const handleSectionClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.addSection === "function") {
                console.log("addSection");
                childRef.current.addSection();
            }
        }
    };

    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    const id = searchParams.get("id");

    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header />
            <AdminHeader
                title="Admin create form"
                onIconClick={() => {
                    console.log();
                }}
            />

            <Box sx={styles.container}>
                <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={EyeIcon} alt="view" />
                </IconButton>

                <input className="titleInput" value={title ?? ""} disabled />

                <Button type="primary" text="Save" onClick={() => handleSaveClick()} />

                <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={TrashIcon} alt="Delete" />
                </IconButton>
            </Box>

            {/* Add Section Box */}
            <Box sx={{ ...styles.container, alignItems: "center" }}>
                <Typography>Add a form Section</Typography>
                <IconButton sx={{ p: 0 }} onClick={() => handleSectionClick()}>
                    <AddCircleRoundedIcon sx={{ color: "#29ABE2 !important", fontSize: 40 }} />
                </IconButton>
            </Box>

            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                <Outlet context={{ childRef }} />
            </Box>
        </Box>
    );
}

export default CreateSurveyLayout;
