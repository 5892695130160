// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Styles

// Constants
import { APIMethodConstants } from "constants/api.method.constants";

// Material Imports
import Grid from "@material-ui/core/Grid";
import { Box, Paper, Typography } from "@mui/material";

// Static Components
import CircularLoadingProgress from "components/CircularProgress";
import { SwalConstants } from "constants/swal.constants";
import NetworkError from "pages/error/networkError";
import { ViewElements } from "pages/user-portal/survey/UserFormBuilder/ViewElements";

// Dynamic Imports

// Utils Import
import { setOpenDialog } from "app/slices/UnpackingSlice";
import { CurrentUserSelector } from "app/slices/UserSlice";
import StepperProgressBar from "components/StepperProgresssBar";
import { FormTypeLabels, FormTypes } from "constants/enum.constants";
import {
    useFormDataPrevNextQuery,
    useSaveSurveyFormAnswersMutation,
    useSaveUnpackingFormAnswersMutation,
    useUserFormData,
} from "hooks/surveyFormData";
import { uniq } from "lodash";
import { useEffect } from "react";
import { SwalAlert } from "utils/swal-utils";

const UserSurveyQuestions = ({
    formType,
    surveyName,
    surveyID,
    sectionId,
    sectionOrder,
    clusterStepId,
    refreshNextClusterStep,
    clusterId,
    vignette_id,
    vignette_text,
    vignette_rank_category,
}) => {
    const [isData, setIsData] = React.useState(false);
    const [selectedSurveySectionDetail, setSelectedSurveySectionDetail] = React.useState([]);
    const [selectedSurveyQuestions, setSelectedSurveyQuestions] = React.useState([]);
    const [formError, setFormError] = useState([]);
    const [currentSection, setCurrentSection] = useState({ order: sectionOrder, id: sectionId });

    const currentUser = useSelector(CurrentUserSelector);
    const dispatch = useDispatch();

    const handleValue = (event, item) => {
        if (formError.length) {
            const filtered = formError.filter((error) => error.id !== item.id);
            setFormError(filtered);
        }
        let answerValue = "";
        switch (item?.answer_type) {
            case "text":
            case "textarea":
            case "number":
            case "dropdown":
            case "radio":
            case "state":
                answerValue = event.target.value;
                break;
            case "date":
            case "time":
                answerValue = event;
                break;
            case "checkbox":
                try {
                    const questionItem = selectedSurveyQuestions.find((el) => el.id === item.id);
                    let checkedValues = questionItem && questionItem["answer"] ? questionItem["answer"] : [];      
                    if (checkedValues.includes(event.target.value)) { // check if value is already selected
                        checkedValues = checkedValues.filter(v => v != event.target.value); // remove selected value
                        answerValue = uniq([...checkedValues]); // add only old selected value
                    } else {
                        answerValue = uniq([...checkedValues, event.target.value]); // add old and new selected value
                    }
                } catch {
                    //
                }
                break;
            case "section":
            default:
        }
        const updatedSurveyQuestionsData = selectedSurveyQuestions.map((question) => {
            if (question.id === item.id) {
                question["answer"] = answerValue;
            }
            return question;
        });
        setSelectedSurveyQuestions(updatedSurveyQuestionsData);
    };

    // Render Form Elements in View Mode
    const renderViewElements = ({ item, index }) => {
        const isRequired = item.is_required === 1;
        let hasError = false;
        if (formError.length && formError.some((error) => error.id === item.id)) {
            hasError = true;
        }
        return ViewElements({
            item,
            handleValue,
            isRequired,
            hasError,
        });
    };

    const onSurveyQuestionsDataFetchSuccess = (data) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        if (data && data.length > 0) {
            const { id, section_title, questions, description } = data[0];
            setSelectedSurveyQuestions(questions);
            setSelectedSurveySectionDetail({ id, section_title, description });
            setIsData(true);
        }
    };

    const onSurveyQuestionsDataFetchError = (error) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    // get current section questions
    const {
        isFetched,
        isFetching: isSurveyQuestionsDataFetching,
        isError: isSurveyQuestionsDataError,
        data: allSurveyQuestionsData,
        error,
        refetch: refetchSurveyQuestionsData,
    } = useUserFormData({
        surveyID,
        formType,
        sectionId: currentSection.id,
        clusterId,
        clusterStepId,
        isEnabled: !!surveyID,
        vignette_id: vignette_id,
        onSuccess: onSurveyQuestionsDataFetchSuccess,
        onError: onSurveyQuestionsDataFetchError,
    });

    // get prev and next sections from the current survey section
    const {
        isFetching: isSurveyPrevNextFetching,
        isError: isSurveyPrevNextError,
        data: surveyPrevNextData,
        refetch: refetchSurveyPrevNext,
    } = useFormDataPrevNextQuery({
        surveyID,
        formType,
        sectionOrder: currentSection.order,
        isEnabled: !!surveyID,
    });

    const { mutate: saveSurveyFormQuestionAnswers, isLoading: isSaving } = useSaveSurveyFormAnswersMutation();

    const { mutate: saveUnpackingFormQuestionAswers, isLoading: isUnpackingSaving } =
        useSaveUnpackingFormAnswersMutation();

    useEffect(() => {
        refetchSurveyQuestionsData();
        refetchSurveyPrevNext();
    }, [currentSection]);

    // We check if the data is fetched and hasn't be set yet to set the data
    // This is to ensure that we set the data with background refetch but don't cause an infinite loop of react setState
    if (isFetched && !isData) {
        if (allSurveyQuestionsData && allSurveyQuestionsData.length > 0) {
            const { id, section_title, questions } = allSurveyQuestionsData[0];
            setSelectedSurveyQuestions(questions);
            setSelectedSurveySectionDetail({ id, section_title });
            setIsData(true);
        }
    }

    const getFormattedPostData = () => {
        let postData = [];
        selectedSurveyQuestions.slice().forEach((eachQuestion) => {
            postData = [
                ...postData,
                {
                    answer: eachQuestion["answer"],
                    answer_type: eachQuestion["answer_type"],
                    question_id: eachQuestion["id"],
                },
            ];
        });
        return postData;
    };

    const validateAnswerData = () => {
        let valid = false;
        const message = "";
        const requiredUnAnsweredQuestions = selectedSurveyQuestions.filter(
            (eachQuestion) => eachQuestion.is_required && !eachQuestion.answer
        );
        if (requiredUnAnsweredQuestions && requiredUnAnsweredQuestions.length > 0) {
            SwalAlert({
                icon: SwalConstants.error,
                title: "Please complete all required fields.",
                description: "",
            });
            valid = false;
            setFormError(requiredUnAnsweredQuestions);
        } else {
            valid = true;
        }
        return { valid, message };
    };

    // Function to handle submit Form
    const handleClusterStepSubmit = async (type) => {
        const { valid } = validateAnswerData();

        if (valid) {
            if (formType === FormTypes.unpacking) {
                saveUnpackingFormQuestionAswers(
                    {
                        data: {
                            container_id: surveyID,
                            form_type: formType,
                            user_id: currentUser.sub,
                            cluster_step_id: clusterStepId,
                            completed_step_id: selectedSurveySectionDetail.id,
                            data: getFormattedPostData(),
                            cluster_id: clusterId,
                            vignette_id: vignette_id,
                            finalSection: surveyPrevNextData.next ? false : true,
                        },
                        apiMethodType: APIMethodConstants.post,
                    },
                    {
                        onSuccess: () => {
                            if (type === "next" && surveyPrevNextData.next) {
                                setCurrentSection({
                                    id: surveyPrevNextData.next.id,
                                    order: surveyPrevNextData.next.section_order,
                                });
                            } else {
                                dispatch(setOpenDialog(false));
                                refreshNextClusterStep(true);
                            }
                        },
                    }
                );
            } else {
                saveSurveyFormQuestionAnswers(
                    {
                        data:
                            formType === FormTypes.unpacking
                                ? {
                                      container_id: surveyID,
                                      form_type: formType,
                                      user_id: currentUser.sub,
                                      cluster_step_id: clusterStepId,
                                      completed_step_id: selectedSurveySectionDetail.id,
                                      data: getFormattedPostData(),
                                      cluster_id: clusterId,
                                      vignette_id: vignette_id,
                                  }
                                : {
                                      container_id: surveyID,
                                      form_type: formType,
                                      user_id: currentUser.sub,
                                      cluster_step_id: clusterStepId,
                                      completed_step_id: selectedSurveySectionDetail.id,
                                      data: getFormattedPostData(),
                                      cluster_id: clusterId,
                                  },
                        apiMethodType: APIMethodConstants.post,
                    },
                    {
                        onSuccess: () => {
                            if (type === "next" && surveyPrevNextData.next) {
                                setCurrentSection({
                                    id: surveyPrevNextData.next.id,
                                    order: surveyPrevNextData.next.section_order,
                                });
                            } else {
                                refreshNextClusterStep(true);
                            }
                        },
                    }
                );
            }
        } else {
            //
        }
    };

    /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (isSurveyQuestionsDataFetching || isSurveyPrevNextFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isSurveyQuestionsDataError) {
        return <NetworkError handleOnRefresh={refetchSurveyQuestionsData} errorText={error} />;
    }

    const handleStepperBack = () => {
        setCurrentSection({ id: surveyPrevNextData.prev.id, order: surveyPrevNextData.prev.section_order });
    };

    return (
        <>
            <Box sx={{ width: "70%", margin: "auto" }}>
                <Box>
                    {/* title */}
                    <Grid display="flex" direction="column" justifyContent="center" alignItems="center" container>
                        <Typography variant="h5" gutterBottom fontWeight={700}>
                            {surveyName} | {FormTypeLabels[formType]}
                        </Typography>
                    </Grid>
                    {/* subtitle */}
                    {selectedSurveySectionDetail?.description && (
                        <Grid
                            display="flex"
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            item
                            xs={12}
                            mb={20}
                        >
                            <Typography mr={15} ml={15} textAlign="center" variant="subtitle2" gutterBottom>
                                <b>Directions: </b> {selectedSurveySectionDetail?.description}
                            </Typography>
                        </Grid>
                    )}
                </Box>
                <Paper
                    sx={{
                        p: "20px 40px 20px 40px",
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "none",
                        borderRadius: "50",
                    }}
                >
                    <Grid container>
                        {/* Additional Header Info */}
                        {/* <Grid item xs={12}>
                            <ContainerHeader
                                title={`${FormTypeLabels[formType]} on ${surveyName}`}
                                sectionTitle={selectedSurveySectionDetail?.section_title}
                                description={selectedSurveySectionDetail?.description}
                                vignette_text={vignette_text}
                                vignette_rank_category={vignette_rank_category}
                                formType={formType}
                            />
                        </Grid> */}
                        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                            {selectedSurveyQuestions?.map((item, index) => renderViewElements({ item, index }))}
                        </Grid>
                    </Grid>
                </Paper>

                {/* Old Stepper */}
                {/* <Paper sx={{ p: 10, mt: 20,  display: "flex", justifyContent: "center", boxShadow: "none", borderRadius: "50" }}>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
                        <VSBox sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 2 }}>
                            <StepperProgress
                                totalSteps={surveyPrevNextData.total}
                                order={surveyPrevNextData.current - 1}
                                handleNext={() => handleClusterStepSubmit("next")}
                                handleBack={handleStepperBack}
                                nextText={surveyPrevNextData.next ? "Next" : "Submit"}
                                loading={isSaving || isUnpackingSaving}
                            />
                        </VSBox>
                    </Grid>
                </Paper> */}

                <Paper
                    sx={{
                        p: 10,
                        mt: 20,
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "none",
                        borderRadius: "50",
                    }}
                >
                    <StepperProgressBar
                        totalSteps={surveyPrevNextData.total}
                        order={surveyPrevNextData.current - 1}
                        handleNext={() => handleClusterStepSubmit("next")}
                        handleBack={handleStepperBack}
                        nextText={surveyPrevNextData.next ? "Next" : "Submit"}
                        loading={isSaving || isUnpackingSaving}
                    />
                </Paper>
            </Box>
        </>
    );
};
export default UserSurveyQuestions;
