import React from "react";

// Loading Indicator
import CircularLoadingProgress from "components/CircularProgress";
import RequireUserAuth from "./RequireUserAuth";

type PropsType = {
    Component: React.FC
}

// `LazyProtectedAdminComponentWrapper` is the function to Lazy Load any component which is Authentication Protected
// 1. Component -> Represents the component that needs to be lazy loaded and Authentication protected
export default function LazyProtectedUserComponentWrapper({ Component }: PropsType) {
    return (
        <RequireUserAuth>
            <React.Suspense fallback={<CircularLoadingProgress />}>
                <Component />
            </React.Suspense>
        </RequireUserAuth>
    );
}
