/* eslint-disable no-unused-expressions */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Card, Grid } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import { Button, Typography, styled } from "@mui/material";
import { CurrentUserSelector } from "app/slices/UserSlice";
import CircularLoadingProgress from "components/CircularProgress";
import VSButton from "components/VSButton";
import { usePostVSetsReactionMutation } from "hooks/VSetsMatching";
import { useVSetReactionQuery } from "hooks/surveyFormData";
import NetworkError from "pages/error/networkError";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThumbsDislike from "../../../../assets/icons/ThumbsDislike.png";
import thumbsLike from "../../../../assets/icons/ThumbsLike.png";
import styles from "../../styles";
import LinearProgressComponent from "../Components/LinearProgress";
import VSortCard from "../Components/VSortCard";

const DisableButton = styled(Button)(({ theme }) => ({
    color: "#94A8B0",
    backgroundColor: "#F5F8FB",
    margin: "0 20px 0 0",
    "&:hover": {
        backgroundColor: "#F5F8FB",
        color: "#94A8B0",
        borderRadius: "8px",
        border: "2px solid #94A8B0",
    },
    borderRadius: "8px",
    border: "2px solid #94A8B0",
}));

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#29ABE2",
    margin: "0 20px 0 0",
    "&:hover": {
        backgroundColor: "rgba(41, 177, 226, 0.8)",
    },
    borderRadius: "8px",
    borderWidth: "2px",
}));

const VSetsEvaluation = ({ containerId, clusterId, clusterStep, domainId, refreshNextClusterStep }: any) => {
    const [totalItems, setTotalItems] = useState(0);
    const [totalLength, setTotalLength] = useState(6);
    const [correctAnswers, setCorrectAnswers] = useState(6);
    const currentUser = useSelector(CurrentUserSelector);
    const [vignetteExplanation, setVignetteExplanation] = useState("");
    const { mutate: postVSetsReaction, loading: postVSetsReactionLoading } = usePostVSetsReactionMutation();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const {
        isFetching: isVSetDataFetching,
        isError: isVSetDataError,
        data: currentDomain,
        error,
        refetch: refetchSurveyQuestionsData,
    } = useVSetReactionQuery({
        surveyID: containerId,
        clusterId,
        isEnabled: !!clusterId,
        userId: currentUser.sub,
        domainId,
    });

    useEffect(() => {
        (async () => {
            if (currentDomain?.length) {
                setTotalLength(currentDomain.length);
                setCorrectAnswers(currentDomain.reduce((n, { flagged }) => n + !flagged, 0));
                setVignetteExplanation(currentDomain[0].explanation);
            }
        })();
    }, [currentDomain]);

    if (isVSetDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isVSetDataError) {
        return <NetworkError handleOnRefresh={refetchSurveyQuestionsData} errorText={error} />;
    }

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleVSetReactionNext = () => {
        postVSetsReaction(
            { userId: currentUser.sub, clusterStepId: clusterStep.id, domainId: clusterStep.domain_id },
            {
                onSuccess: () => {
                    window.location.reload();
                    // refreshNextClusterStep(true)
                    // setSelectedCategoryOpen(false);
                },
            }
        );
    };

    const handleExit = () => {
        navigate("/dashboard");
    };

    return (
        <Grid
            container
            spacing={2}
            sx={{
                backgroundColor: "#fff",
                marginTop: "2rem",
                borderRadius: "20px",
                width: "100%",
                justifyContent: "space-evenly",
            }}
        >
            <Grid item xs={6}>
                <Grid
                    container
                    sx={{ flexDirection: "column", width: "100%", padding: " 0 2rem 2rem 0rem", minHeight: "100%" }}
                >
                    {/* <Grid item>
                <LinearProgressComponent totalItems={currentDomain.length} totalLength={currentDomain.length} totalProgress={100} />
              </Grid> */}
                    <Grid item>
                        <Typography
                            sx={{
                                width: "100%",
                                // marginTop: "2rem",
                                padding: "1rem",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "0.5rem",
                            }}
                        >
                            Congrats! you got{" "}
                            <span style={{ fontWeight: 700, color: "#009245" }}>
                                {correctAnswers} / {totalLength} vignettes correct.
                            </span>
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        flexDirection="column"
                        sx={{
                            borderRadius: "0.5rem",
                            background: " #F9F9F9",
                            marginTop: "1.5rem",
                            padding: "1.5rem !important",
                            flexGrow: "1",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontWeight: 700, color: "#29ABE2", fontSize: "1rem", marginBottom: "1.5rem" }}
                                >
                                    Explanation
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ fontSize: "0.8rem" }}>{vignetteExplanation}</Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                justifyContent: "flex-end",
                            }}
                        >
                            <img src={ThumbsDislike} alt="thumbsdislike" style={{ cursor: "pointer" }} />
                            <img src={thumbsLike} alt="thumbslike" style={{ cursor: "pointer" }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item gap={12} xs={6} sx={{ flexBasis: "45%" }}>
                <Card elevation={0} style={{ width: "100%", padding: "1rem", minHeight: "535px" }}>
                    <Grid container gap={12}>
                        {currentDomain.map((item) => (
                            <Grid item xs={12} style={{ cursor: "pointer" }}>
                                <VSortCard
                                    area="DISABLE"
                                    item={item}
                                    hoverItem={() => {
                                        console.log("hovered");
                                    }}
                                    setVignetteExplanation={setVignetteExplanation}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Card>
            </Grid>
            <Grid
                container
                xs={12}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                }}
            >
                <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                    <LinearProgressComponent
                        totalItems={totalLength}
                        totalLength={totalLength}
                        totalProgress={(totalLength / totalLength) * 100}
                    />
                </Grid>
                <Grid>
                    {/* <IconButton sx={{ mr: 20 }} onClick={handleReset}>
                        <RefreshIcon sx={{ transform: "rotate(270deg)", color: "#94A8B0", fontSize: "2rem" }} />
                    </IconButton> */}
                    <VSButton variant="contained" sx={{ ...styles.btnExit, boxShadow: "none" }} onClick={handleExit}>
                        Exit
                    </VSButton>
                    &nbsp;&nbsp;&nbsp;
                    <ColorButton
                        loading
                        onClick={handleVSetReactionNext}
                        variant="contained"
                        sx={{ ...styles.btnContinue, boxShadow: "none" }}
                    >
                        Next
                    </ColorButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VSetsEvaluation;
