const styles: any = {
    container: {
        background: '#fff',
        borderRadius: 10,
        padding: '30px 30px 16px',
        height: '100%',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: '#000',
    },
    btnTab: {
        border: 'none',
        marginLeft: 17,
        textTransform: 'none',
    },
    selected: {
        fontWeight: 800,
        fontSize: 16,
        color: '#29ABE2',
    },
    unselected: {
        fontSize: 16,
        color: '#929292',
    },
    users: {
        marginTop: 55,
        overflowY: 'auto',
        paddingRight: 30,
        paddingLeft: 10,
        height: 340,
    },
    user: {
        width: '100%',
        height: 94,
        padding: '10px 24px',
        background: '#fff',
        borderRadius: 10,
        boxShadow: '0px 10px 10px 10px rgba(41, 171, 226, 0.11)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 12,
        cursor: 'pointer',
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    name: {
        fontSize: 16,
        color: '#000',
    },
    score: {
        fontSize: 14,
        color: '#f00',
    },
    clusterIcon: {
        width: 14,
        height: 14,
        marginRight: 4,
    },
    resultsText: {
        fontWeight: 500,
        fontSize: 14,
        textDecoration: 'underline',
        color: '#929292',
    },
    progressText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: '#CA7D0B',
    },
    pendingText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: '#929292',
    },
    btnContinue: {
        width: 135,
        height: 42,
        borderRadius: 30,
        border: '1px solid #29ABE2',
        background: '#29ABE2',
        color: '#fff',
        fontSize: 16,
        textTransform: 'none',
        marginRight: 24,
    },
    btnSubmitted: {
        width: 135,
        height: 42,
        borderRadius: 30,
        border: '1px solid #929292',
        background: '#929292',
        color: '#fff !important',
        fontSize: 16,
        textTransform: 'none',
        marginRight: 24,
    },
    greenCircle: {
        width: 10,
        height: 10,
        background: '#29ABE2',
        borderRadius: '50%',
    },
    grayCircle: {
        width: 10,
        height: 10,
        background: '#929292',
        borderRadius: '50%',
    },
}

export default styles