import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

const fetchFormInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchSurveyQuestions + url, method: APIMethodConstants.get });
};

const fetchFormResponsesInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchSurveyResponses + url, method: APIMethodConstants.get });
};

const fetchUserFormInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchUserSurveyQuestions + url, method: APIMethodConstants.get });
};

const fetchFormPrevNextInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchSurveyPrevNext + url, method: APIMethodConstants.get });
};

const fetchVSetInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchVSetQuestions + url, method: APIMethodConstants.get });
};

const fetchVSetReactionInformation = ({ url }: any) => {
    return request({ url: NetworkConstants.fetchVSetReaction + url, method: APIMethodConstants.get });
};

const createEditFormQuestions = ({ apiMethodType, data }: any) => {
    return request({ url: NetworkConstants.createEditSurveyQuestions, method: apiMethodType, data: data });
};

export const deleteFormQuestion = (id: any, data: any) => {
    return request({ url: `${NetworkConstants.deleteSurveyQuestion}/${id}`, method: APIMethodConstants.post, data });
};

export const bulkDeleteFormQuestions = (ids: any, data: any) => {
    return request({ url: `${NetworkConstants.bulkeleteSurveyQuestion}`, method: APIMethodConstants.post, data: {...data, form_ids: ids} });
};

export const saveSurveyFormQuestionAnswers = ({ apiMethodType, data }: any) => {
    return request({ url: NetworkConstants.saveSurveyFormQuestionAnswers, method: apiMethodType, data });
};

export const saveUnpackingFormQuestionAnswers = ({ apiMethodType, data }: any) => {
    return request({ url: NetworkConstants.saveUnpackingFormQuestionAnswers, method: apiMethodType, data });
};

export const saveVSetOrder = ({ apiMethodType, data }: any) => {
    return request({ url: NetworkConstants.saveVSetOrder, method: apiMethodType, data });
};

export const deleteSection = ({ apiMethodType, data }: any) => {
    return request({ url: NetworkConstants.deleteSurveySection, method: apiMethodType, data });
};

// useFormData -> To fetch Questions of a particular Survey (Pre-Survey, Post-Survey and Final Feedback)
export const useFormData = ({ surveyID, formType, sectionId, onSuccess, onError, clusterStepId, isEnabled = true }: any) => {
    const sectionQuery = sectionId ? `&section=${sectionId}` : "";
    return useQuery(
        [QueryKeyConstants.fetchSurveyQuestionsQueryKey, formType, surveyID, clusterStepId, sectionId],
        () => fetchFormInformation({ url: surveyID + "&form_type=" + formType + sectionQuery }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useFormResponsesData -> To fetch Responses of a particular Survey (Pre-Survey, Post-Survey and Final Feedback)
export const useFormResponsesData = ({ surveyID, formType, sectionId, onSuccess, onError, clusterStepId, isEnabled = true }: any) => {
    const sectionQuery = sectionId ? `&section=${sectionId}` : "";
    return useQuery(
        [QueryKeyConstants.fetchSurveyResponsesQueryKey, formType, surveyID, clusterStepId, sectionId],
        () => fetchFormResponsesInformation({ url: surveyID + "&form_type=" + formType + sectionQuery }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useFormData -> To fetch Questions of a particular Survey (Pre-Survey, Post-Survey and Final Feedback)
export const useUserFormData = ({
    surveyID,
    formType,
    sectionId,
    onSuccess,
    onError,
    clusterId,
    clusterStepId,
    vignette_id,
    isEnabled = true,
}: any) => {
    const sectionQuery = sectionId ? `&section=${sectionId}` : "";
    const clusterIdQuery = clusterId ? `&cluster_id=${clusterId}` : "";
    const clusterStepIdQuery = clusterStepId ? `&cluster_step_id=${clusterStepId}` : "";
    const vignetteIdQuery = vignette_id ? `&vignette_id=${vignette_id}` : ``;
    return useQuery(
        [QueryKeyConstants.fetchUserSurveyQuestionsQueryKey, formType, surveyID, clusterStepId, sectionId],
        () =>
            fetchUserFormInformation({
                url: surveyID + "&form_type=" + formType + sectionQuery + clusterIdQuery + clusterStepIdQuery + vignetteIdQuery,
            }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useFormData -> To fetch Questions of a particular Survey (Pre-Survey, Post-Survey and Final Feedback)
export const useFormDataPrevNextQuery = ({
    surveyID,
    formType,
    sectionOrder,
    onSuccess,
    onError,
    isEnabled = true,
}: any) => {
    return useQuery(
        [QueryKeyConstants.fetchSurveyQuestionsPrevNextQueryKey, formType, surveyID, sectionOrder],
        () =>
            fetchFormPrevNextInformation({
                url: surveyID + "&form_type=" + formType + "&section_order=" + sectionOrder,
            }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useFormData -> To fetch Questions of a particular Survey (Pre-Survey, Post-Survey and Final Feedback)
export const useVSetQuestionQuery = ({
    clusterId,
    surveyID,
    domainID,
    onSuccess,
    onError,
    clusterStepId,
    completedDomainName,
    isEnabled = true,
    userId
}: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSetInformation, surveyID, clusterStepId, completedDomainName, domainID, userId],
        () => fetchVSetInformation({ url: `${surveyID}&domain_id=${domainID}&user_id=${userId}&cluster_id=${clusterId}` }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data[0];
            },
        }
    );
};

// useFormData -> To fetch Questions of a particular Survey (Pre-Survey, Post-Survey and Final Feedback)
export const useVSetReactionQuery = ({
    surveyID,
    onSuccess,
    onError,
    isEnabled = true,
    userId,
    clusterId,
    domainId
}: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSetReactionInformation, surveyID, userId, clusterId],
        () => fetchVSetReactionInformation({ url: `${surveyID}&cluster_id=${clusterId}&user_id=${userId}&domain_id=${domainId}` }),
        {
            enabled: isEnabled,
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useCreateEditFormData -> To add/edit data to a New/Existing Survey (Pre-Survey, Post-Survey and Final Feedback)
export const useCreateEditFormData = ({ data, apiMethodType }: any) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(() => createEditFormQuestions({ data, apiMethodType }), {
        // We navigate back to the previous page if the Network Call is successful
        onSuccess: (_res) => {
            const { form_type: formType, container_id: surveyID, clusterStepId } = data;
            navigate(-1);
            queryClient.invalidateQueries([
                QueryKeyConstants.fetchSurveyQuestionsQueryKey,
                formType,
                surveyID,
                clusterStepId,
            ]);
            queryClient.invalidateQueries([QueryKeyConstants.fetchSurveyQueryKey, formType]);
        },
    });
};

// useSaveSurveyAnswersFormData -> To save question answers (Pre-Survey, Post-Survey and Final Feedback)
// export const useSaveSurveyAnswersFormData = ({ data, apiMethodType }) => {
//     const navigate = useNavigate();
//     return useMutation(() => saveSurveyFormQuestionAnswers({ data, apiMethodType }), {
//         onSuccess: (_) => {
//             navigate(-1);
//         },
//     });
// };

// useSaveVSortMutation -> To save VSorts answers
export const useSaveVSortMutation = () => {
    return useMutation(({ data, apiMethodType }: any) => saveVSetOrder({ data, apiMethodType }));
};

// useSaveSurveyAnswersFormData -> To save question answers (Pre-Survey, Post-Survey and Final Feedback)
export const useSaveSurveyFormAnswersMutation = () => {
    return useMutation(({ data, apiMethodType }: any) => saveSurveyFormQuestionAnswers({ data, apiMethodType }));
};

// useSaveUnpackingFormAnswersMutation -> To save unpacking answers
export const useSaveUnpackingFormAnswersMutation = () => {
    return useMutation(({ data, apiMethodType }: any) => saveUnpackingFormQuestionAnswers({ data, apiMethodType }));
};

// useDeleteSection -> To delete multiple elements of a section
export const useDeleteSection = () => {
    return useMutation(({ data, apiMethodType }: any) => deleteSection({ data, apiMethodType }));
};
