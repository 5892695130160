import { MenuItem, Select } from "@mui/material";
import styles from "./styles";

export type PropsType = {
    options: OptionType[];
    onChange: (value: any) => void;
    selectedValue?: any;
};

export type OptionType = {
    value: any;
    text: string;
};

const AdminSelect = ({ options, onChange, selectedValue }: PropsType) => {
    const handleOnChange = (value: string) => {
        onChange(value);
    };

    return (
        <Select
            value={selectedValue}
            onChange={(e) => handleOnChange(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            fullWidth
            sx={styles.dropdown}
        >
            {options.map((option) => {
                return <MenuItem value={option.value}>{option.text}</MenuItem>;
            })}
        </Select>
    );
};

export default AdminSelect;
