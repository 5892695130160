// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "pages/layouts/navigation/LoginButton";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            const loginBtn = document.getElementById("auth0-login-button");
            if (loginBtn) {
                loginBtn.click();
            }
        }
    }, [isAuthenticated]);

    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return <LoginButton hide />;
};

export default LoginPage;
