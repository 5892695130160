import { USER_ROLE_ADMIN, USER_ROLE_NORMAL, USER_ROLE_SUPER_ADMIN } from "constants/user.constants";

export enum UserRole {
    NORMAL = USER_ROLE_NORMAL,
    ADMIN = USER_ROLE_ADMIN,
    SUPER_ADMIN = USER_ROLE_SUPER_ADMIN,
}

export type User = {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    nickname: string;
    email: string;
    img: string;
    role: UserRole;
};

export type ClusterUser = {
    id: string;
    first_name: string;
    last_name: string;
    name: string;
    email: string;
};
