import { Grid, Typography } from "@mui/material";
import CircularLoadingProgress from "components/CircularProgress";
import VSBorderLinearProgress from "components/VSBorderLinearProgress";
import VSCard from "components/VSCard/VSCard";
import { FormTypes } from "constants/enum.constants";
import { useGetUnpackingQuestions } from "hooks/VSortsContainerData";
import NetworkError from "pages/error/networkError";
import VignetteTextDisplay from "pages/user-portal/parkingLot/vignetteTextDisplay";
import { useEffect, useState } from "react";
import UnpackingSurveyForm from "./UnpackingSurveyForm";
import styles from "./styles";
export default function Unpacking(props: any) {
    const [clusterStepData, setClusterStepData] = useState<any>([{}]);
    const [isData, setIsData] = useState(false);
    const [surveyPrevNextData, setSurveyPrevNextData] = useState<any>();
    const {
        onClose,
        selectedValue,
        open,
        vignette_id,
        vignette_text,
        vignette_rank_category,
        cluster_step_id,
        refetchFlaggedData,
    } = props;

    useEffect(() => {
        refetchUnpackingQuestionData();
    }, []);

    const onQuestionFetchSuccess = (data: any) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        setClusterStepData(data);
        setIsData(true);
    };

    const onQuestionFetchError = () => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    const {
        isFetched,
        isFetching: isQuestionDataFetching,
        // isLoading: isNextClusterStepDataLoading,
        isError: isQuestionDataError,
        data,
        error,
        refetch: refetchUnpackingQuestionData,
    } = useGetUnpackingQuestions({
        clusterID: props.cluster_id,
        container_id: props.container_id,
        userID: props.user_id,
        form_type: "unpacking",
        cluster_step_id: cluster_step_id,
        vignette_id: vignette_id,
        onSuccess: onQuestionFetchSuccess,
        onError: onQuestionFetchError,
    });

    /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (isQuestionDataFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isQuestionDataError) {
        return <NetworkError handleOnRefresh={refetchUnpackingQuestionData} errorText={error} />;
    }

    if (isFetched && !isData) {
        setClusterStepData(data);
        setIsData(true);
    }

    const refreshNextClusterStep = (value: any) => {
        if (value) {
            refetchFlaggedData();
        }
    };

    const handleClose = () => {
        onClose(selectedValue);
    };

    const setPrevNextDataFunction = (data: any) => {
        setSurveyPrevNextData(data);
    };

    return (
        <VSCard sx={styles.outerCard}>
            <Grid container spacing={2} px={1}>
                <Grid item xs={2}>
                    <Typography variant="body2" sx={styles.completionStatus}>
                        {surveyPrevNextData?.current}/{surveyPrevNextData?.total} questions
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <VSBorderLinearProgress
                        variant="determinate"
                        value={(surveyPrevNextData?.current / surveyPrevNextData?.total) * 100}
                    />
                </Grid>
            </Grid>
            <VignetteTextDisplay vignette_text={vignette_text} />
            {clusterStepData &&
                clusterStepData.next_cluster_step &&
                clusterStepData.next_cluster_step["form_type"] === FormTypes.unpacking && (
                    <UnpackingSurveyForm
                        clusterStep={clusterStepData.next_cluster_step}
                        refreshNextClusterStep={refreshNextClusterStep}
                        clusterDetail={clusterStepData.cluster_detail}
                        vignette_id={vignette_id}
                        vignette_text={vignette_text}
                        vignette_rank_category={vignette_rank_category}
                        setSurveyPrevNextData={setPrevNextDataFunction}
                    />
                )}
        </VSCard>
    );
}
