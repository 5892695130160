const styles: any = {
    container: {
        background: "#fff",
        borderRadius: 10,
        padding: "24px 32px",
        height: "288px",
        minHeight: 400,
        marginTop: 15,
        position: "relative",
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: "#000",
        position: "absolute",
        left: "55px",
    },
    warning: {
        fontWeight: 500,
        fontSize: 16,
        color: "#f00",
        marginTop: 20,
    },
    chartContainer: {
        // display: "flex",
        position: "absolute",
        flexDirection: "row",
        top: "25%",
        right: "55px",
        bottom: "146px",
        left: "55px",
        width: "655px",
        height: "200px",
        borderLeft: "2px solid #94A8B0",
        borderBottom: "2px solid #94A8B0",
        background: "#F3F8FB",
    },
    xAxisTitle: {
        position: "absolute",
        top: "182px",
        left: "20px",
        transform: "rotate(-90deg)",
    },
    yAxisTitle: {
        position: "absolute",
        top: "300px",
        left: "359px",
        right: "360px",
    },
};

export default styles;
