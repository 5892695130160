// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState } from "react";

// Material Imports
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ReorderIcon from "@mui/icons-material/Reorder";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// components import
import VSDialog from "components/VSDialog";
import { useEffect } from "react";
import { arrayMove, SortableContainer as sortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

const DragHandle = SortableHandle(() => <DragIndicatorIcon color="disabled" sx={{ mr: 2, cursor: "all-scroll" }} />);

const SortableItem = SortableElement(({ item, count, total }: any) => {
    return (
        <ListItem disableGutters className="z-index-super-visible">
            <DragHandle />
            <ListItemText primary={item.section_title} secondary={`Section ${count} of ${total}`} />
        </ListItem>
    );
});

const SortableContainer = sortableContainer(({ children }: any) => {
    return <List disablePadding>{children}</List>;
});

const SectionOrderDialog = ({ onChange, data }: any) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState(data);

    useEffect(() => {
        setItems(data);
    }, [data]);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        setItems(arrayMove(items, oldIndex, newIndex));
    };

    return (
        <>
            <Tooltip title="Reorder Sections" aria-label="reorder-sections">
                <IconButton
                    aria-label="reorder-sections"
                    onClick={() => setOpen(true)}
                    sx={{ position: "sticky", top: 30 }}
                >
                    <ReorderIcon color="secondary" />
                </IconButton>
            </Tooltip>
            <VSDialog
                open={open}
                handleClose={() => setOpen(false)}
                title="Reorder sections"
                handleConfirm={() => {
                    onChange(items);
                    setOpen(false)
                }}
            >
                <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                    {items.map((item: any, index: number) => (
                        <SortableItem
                            key={`item-${item.id}`}
                            index={index}
                            count={index + 1}
                            item={item}
                            total={data.length}
                        />
                    ))}
                </SortableContainer>
            </VSDialog>
        </>
    );
};

export default SectionOrderDialog;
