import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { uid } from 'react-uid';
import './CustomTable.css';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: '#929292',
    padding: '15px',
    borderBottom: '1px solid #E4E4E4 !important'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 500,
    color: '#000'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFF',
  },
  '&:last-child td, &:last-child th': {
    borderRadius: '',
    border: 0,
  },
}));

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface Action {
  tooltip: string;
  icon: JSX.Element;
  isConditionalRendering?: boolean,
  conditionalRendering: (row: any) => void,
  onClick: (row: any) => void;
}


interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  isSelect: boolean;
  columns: [];
  selected: readonly number[];
  headerActions: Action[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    isSelect,
    headerActions,
    selected
  } = props;

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {isSelect ? (
          <StyledTableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </StyledTableCell>
        ) : (
          <></>
        )}
        {columns.map((headCell: any) => 
        {
          if(headCell.id == "Actions") {
            return (
              <StyledTableCell
                key={headCell.id}
                align='left'
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                Actions
                {
                  headerActions.map((action: Action) => (
                    <>
                      <Tooltip title={action.tooltip} arrow>
                        <IconButton
                          sx={{marginLeft: "10px"}}
                          onClick={() => action.onClick(selected)}
                          key={uid(action)}
                        >
                          {action.icon}
                        </IconButton>
                      </Tooltip>
                    </>
                  ))
                }
              </StyledTableCell>
            )
          } else {
            return (
              <StyledTableCell
                key={headCell.id}
                align='left'
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            )
          }
        }
        )}
      </StyledTableRow>
    </TableHead>
  );
}

interface TableComponentProps {
  rows: [];
  isSelect?: boolean;
  rowActions: Action[]; // Array of objects
  headerActions: Action[]; // Array of objects
  selected: readonly number[];
  isDensePadding?: boolean;
  hideColumns?: string[];
  setSelected: Dispatch<SetStateAction<readonly number[]>>;
}


const TableComponent:React.FC<TableComponentProps> = ({
  rows = [],
  isSelect = true,
  rowActions,
  headerActions,
  setSelected,
  selected,
  isDensePadding = false,
  hideColumns = ["id"]
}) => {
  const [tableRows, setTableRows] = useState([...rows]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(isDensePadding);
  const [columns, setColumns] = useState<[]>([]);
  const [columnKeys, setColumnKeys] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  useEffect(() => {
    const clonedRows = _.cloneDeep(rows);
    setTableRows(clonedRows);
    
    let columnKeys: any = Object.keys(tableRows[0]);
    columnKeys = columnKeys.filter((col: string) => !hideColumns.includes(col));
    if (rowActions?.length) {
      columnKeys = [...columnKeys, 'Actions'];
    }

    const columnArray: any = columnKeys?.map((column: any) => ({
      id: column,
      label: column,
      disablePadding: false,
    }));
    setColumnKeys(columnKeys);
    setColumns(columnArray);
  }, [rows]);

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked)
    if (event.target.checked) {
      const newSelected: any = tableRows.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(tableRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, tableRows]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableRows.length}
              columns={columns}
              isSelect={isSelect}
              selected={selected}
              headerActions={headerActions}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={uid(row)}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    {isSelect && (
                      <StyledTableCell padding='checkbox' align='center'>
                        <Checkbox
                          color='primary'
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </StyledTableCell>
                    )}

                    {columnKeys.map((column, index) => {
                      if (column == 'id') {
                        return (
                          <StyledTableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            key={uid(column)}
                          >
                            {row.id}
                          </StyledTableCell>
                        );
                      } else if (rowActions && column == 'Actions') {
                        return (
                        <StyledTableCell key={uid(column)}>
                          {
                             rowActions.map((action: Action) => (
                              <>
                                    {
                                      action.isConditionalRendering ? (
                                        action.conditionalRendering(row)
                                      ) : (
                                      <Tooltip title={action.tooltip} arrow>
                                        <IconButton
                                          onClick={() => action.onClick(row)}
                                        >
                                          {action.icon}
                                        </IconButton>
                                      </Tooltip>
                                      )
                                    }
                              </>
                            ))
                          }
                        </StyledTableCell>
                        );
                      } else if(column == 'deployed' || column == 'Verified') {
                        return (
                          <StyledTableCell key={uid(column)}>
                            <div className='deployed_cell'>
                              <div className={`deployed ${row[column] ? "active" : ""}`}></div>
                              {row[column] ? "Yes" : "No"}
                            </div>
                          </StyledTableCell>
                        );
                      } else if (column != 'id') {
                        return (
                          <StyledTableCell align='left' key={uid(column)}>
                            {row[column]}
                          </StyledTableCell>
                        );
                      }
                    })}
                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <StyledTableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default TableComponent;