import { Box, IconButton, Typography } from "@mui/material";
import GridIcon from "assets/icons/grid.svg";
import styles from "./styles";

type PropsType = {
    title?: string;
    onIconClick: () => void;
};

function AdminHeader({ title = "Admin", onIconClick }: PropsType) {
    return (
        <Box sx={styles.titleContainer}>
            <Typography sx={styles.title}>{title}</Typography>
            <Box sx={styles.flexContainer}>
                <IconButton onClick={() => onIconClick()}>
                    <img src={GridIcon} alt="#" style={styles.actionIcon} />
                </IconButton>
            </Box>
        </Box>
    );
}

export default AdminHeader;
