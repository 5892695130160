import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import VSTypography from 'components/VSTypography';
import { APIMethodConstants } from 'constants/api.method.constants';
import { StringConstants } from 'constants/string.constants';
import { SwalConstants } from 'constants/swal.constants';
import { useGetVSetsActivityTypeQuery } from 'hooks/VSetsActivityTypeData';
import { useGetVSetsCategoriesQuery } from 'hooks/VSetsCategoryData';
import { deleteVignettes, useAddEditVSortsContainerData, useVSortsContainerCategoryData, useVSortsContainerData } from 'hooks/VSortsContainerTableData';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { SwalAlert } from 'utils/swal-utils';
import ReactSelect from '../../../Components/Select';
import TableRows from './TableRows';
import "./VSet.css";

const CreateVset = forwardRef(function CreateVset() {
  const { childRef }: any = useOutletContext();

  const { isCreate = false } = useParams();

  const [activityTypes, setActivityTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]); 
  const [activityType, setActivityType] = useState(""); 
  const [rowsData, setRowsData] = useState<any[]>([]);

  const searchParams = new URLSearchParams(window.location.search);
  const title = searchParams.get('title');
  const containerID = searchParams.get('id');

  const {
    isLoading: isLoadinActivityTypes,
    data: activityTypesData,
    isError: isErrorActivityTypes,
} = useGetVSetsActivityTypeQuery({
  onSuccess: (data: any) => {
    const activityTypesData = data?.map((ele: any) => ({
      label: ele.activity_type,
      value: ele.id,
    }))
    setActivityTypes(activityTypesData)
  },
  onError: (error: any) => {
    console.log(error)
  }
});

const {
  isLoading: isAllVSetsCategoryDataLoading,
  data: allVSetsCategoryData,
  isError: isAllVSetsCategoryDataError,
  error: isVSetCategoryError,
  refetch: refetchAllVSetsCategoryData,
} = useGetVSetsCategoriesQuery();

useEffect(() => {
  const categoriesData = allVSetsCategoryData?.map((ele: any) => ({
    label: ele.category_name,
    value: ele.id,
  }))
  setCategories(categoriesData)
}, [allVSetsCategoryData])


const onVSortsContainerCategoriesDataSuccess = (data: any) => {
  // TODO:- Perform any operation after the successful fetching of Survey Data
  // 
};

const onVSortsContainerCategoriesDataError = (error: any) => {
  // TODO:- Perform any operation in case of errors while fetching Survey Data
  // 
};

const {
  isFetching: isVSortsContainerCategoriesDataFetching,
  isLoading: isVSortsContainerCategoriesDataLoading,
  isError: isVSortsContainerCategoriesDataError,
  data: VSortsContainerCategoriesData,
  error,
  refetch: refetchVSortsContainerCategoriesData,
} = useVSortsContainerCategoryData({
  onSuccess: onVSortsContainerCategoriesDataSuccess,
  onError: onVSortsContainerCategoriesDataError,
  containerID: containerID,
});


const onVSortsContainerDataDataError = (error: any) => {
  // TODO:- Perform any operation in case of errors while fetching Survey Data
  // 
};

  const {
    isFetching: isVSortsContainerDataFetching,
    isLoading: isVSortsContainerDataLoading,
    isError: isVSortsContainerDataError,
    data: VSortsContainerData,
    error: VSortsContainerDataError,
    refetch: refetchVSortsContainerData,
  } = useVSortsContainerData({
    isEnabled: !isCreate,
    containerID: containerID,
    onError: onVSortsContainerDataDataError,
  });

    // Update current selected categories after fetching container categories and container data
    useEffect(() => {
      const parseVSortsContainerToRowDataVariable = () => {
          const parsedRowData: any[] = [];
          (VSortsContainerData || []).forEach((eachRow: any) => {
            console.log(VSortsContainerData)
              const rowItem: any = {
                  ids: [],
                  domain: "",
                  header_text: "",
                  footer_text: "",
                  sources: [],
                  sparks: [],
                  vignettes: [],
                  explanation: [],
              };
              rowItem.domain_name = eachRow.domain_name;
              rowItem.domain_id = eachRow.domain_id;
              rowItem.header_text = eachRow.header_text || StringConstants.vignetteDefaultHeaderText;
              rowItem.footer_text = eachRow.footer_text || StringConstants.vignetteDefaultFooterText;

              (VSortsContainerCategoriesData || []).forEach((category: any) => {
                  const catItem = eachRow.vignettes.find((item: any) => item.category_id === category.id) || {};
                  rowItem.ids.push(catItem.id || "");
                  rowItem.sparks.push(catItem.spark || "");
                  rowItem.vignettes.push(catItem.vignette_text || "");
                  rowItem.explanation.push(catItem.explanation || "");
                  rowItem.sources.push(String(JSON.parse(catItem.sources || '""')));
              });
              parsedRowData.push(rowItem);
          });
          setRowsData(parsedRowData);
      };
      if (VSortsContainerCategoriesData && VSortsContainerData) {
          parseVSortsContainerToRowDataVariable();
          setCategory(
              VSortsContainerCategoriesData.map((item: any) => ({ ...item, label: item.category_name, value: item.id }))
          );
      }
  }, [VSortsContainerCategoriesData, VSortsContainerData]);

const isValidCategoryData = category.length > 1;

// Function to add a new Row in the VSorts Container Table
const addTableRows = () => {
  // `rowsInput` represents an empty new row
  const categoryLen = category.length;
  const rowsInput = {
      domain: [],
      sparks: new Array(categoryLen).fill(""),
      vignettes: new Array(categoryLen).fill(""),
      sources: new Array(categoryLen).fill(""),
      explanation: new Array(categoryLen).fill(""),
      header_text: StringConstants.vignetteDefaultHeaderText,
      footer_text: StringConstants.vignetteDefaultFooterText,
  };

  // We append the newly created empty row to the rows Data
  setRowsData([...rowsData, rowsInput]);
};

// Function to delete the selected row
const deleteTableRows = (index: number) => {
  const item = rowsData[index];
  if (item.domain_id) {
      deleteVignettes({ domain_id: item.domain_id });
  }
  const rows = [...rowsData];
  rows.splice(index, 1);
  setRowsData(rows);
};

const handleChange = ({ index, event, parentIndex, inputType }: any) => {
  // rowsInput represents the current value of rowsData (Which contains data of all the rows)
  const rowsInput = [...rowsData];

  // We check if the inputType is domain. This is done to add the value of the input text to the domain key in rowsData
  if (inputType === "domain") {
      const { value } = event.target;

      // We add the input to the domain key in the current row (Each row only consists of a Single Domain Field)
      rowsInput[parentIndex]["domain_name"] = value;
  }
  // update header_text of the current row
  else if (inputType === "header_text") {
      const { value } = event.target;
      rowsInput[parentIndex]["header_text"] = value;
  }
  // update footer_text of the current row
  else if (inputType === "footer_text") {
      const { value } = event.target;
      rowsInput[parentIndex]["footer_text"] = value;
  }

  // Or else, we add the the input to the relevant cell using `inputType` and `index`
  else {
      const { value } = event.target;
      rowsInput[parentIndex][inputType][index] = value;
  }

  setRowsData(rowsInput);
};

const {
  mutate: addEditVSortsContainerData,
  isError: isAddEditVSortsContainerDataError,
  reset: resetAddEditVSortsContainerDataMutation,
} = useAddEditVSortsContainerData();

// called when save button is clicked on the parent component
useImperativeHandle(childRef, () => {
  return {
      // save/update vset parameters
      save() {
      // isTableValid - Represents if the all the entries in the Table are filled (Sparks are optional)
      let isTableValid = true;

      // body -> Represents the body that needs to sent via the POST request to add the table data to the VSorts Container
      const body: any = {};

      // We set the containerID obtained using `useParams()`
      body["container_id"] = containerID;
      body["container_name"] = title;

      const categoriesData = category.map((cat: any) => ({
        "id": cat.value,
        "category_name": cat.label
      }))

      body["categories"] = categoriesData;

      // We set the `vignettes` key to empty to enable us to push to the array
      body["data"] = [];

      rowsData.forEach((rowData, _index) => {
          // innerBody -> Represents the data of each row
          const rowItem: any = {
              domain_name: rowData.domain_name,
              header_text: rowData.header_text,
              footer_text: rowData.footer_text,
              domain_id: rowData.domain_id,
          };

          // currentRow -> Repersents the data that will be present in all the cells (vignette, spark and source) for each row
          const currentRow: any[] = [];

          // We iterate through the various categories (columns) to extract data from each cell for that row
          categoriesData.forEach((category: any, index: number) => {
              // Index 0 is skipped since that's the Domain
              // We push the data to `currentRow` by extracting  spark, vignette and sources
              currentRow.push({
                  id: rowData.ids && rowData.ids[index] ? rowData.ids[index] : "",
                  category_id: category.id,
                  assigned_rank: index + 1, // this is for assigning a rank to the vignette depending on how they are created left --> right
                  spark: rowData.sparks[index] ? rowData.sparks[index] : "",
                  vignette_text: rowData.vignettes[index] ? rowData.vignettes[index] : "",
                  explanation: rowData.explanation[index] ? rowData.explanation[index] : "",
                  sources:
                      rowData.sources && rowData.sources[index] && rowData.sources[index].length > 0
                          ? rowData.sources[index].split(",")
                          : [],
              });
          });

          // The extracted row (currentRow) is then set to the data key of the current row (rowItem)
          rowItem["vignettes"] = currentRow;

          // Once all the data for the row is extracted, we push it to the vignette key of the body
          body["data"].push(rowItem);
      });

      // Functions to check if the VSorts Container Table Data is Valid - START

      // If there are no rows present in the Table, we set `isTableValid` is false
      if (!rowsData.length) {
          isTableValid = false;
      }

      rowsData.every((rowItem, _index) => {
          // We check if the domain is entered
          if (!rowItem.domain_name) {
              isTableValid = false;
              // Acts as a break in a forEach loop
              return false;
          }
          // Used to keep the loop running
          return true;
      });
      // Functions to check if the VSorts Container Table Data is Valid - END

      console.log()

      if (isTableValid) {
          addEditVSortsContainerData({
              apiMethodType: APIMethodConstants.post,
              data: body,
          });
      } else {
          SwalAlert({
              icon: SwalConstants.error,
              title: StringConstants.vSortsContainerDataNotFilledTitle,
              description: StringConstants.vSortsContainerDataNotFilledDescription,
          });
      }
      },
  };
});

  return (
    <div className='createVSet'>
      <div className='createVSetContainer'>
        <p>Choose an activity type to select categories</p>
        <div className='createVSetContainer__selectCat'>
          <ReactSelect data={activityTypes} label={"Activity Type"} defaultValue={activityType} setValue={setActivityType} />
          <ReactSelect data={categories} isMulti={true} defaultValue={category} setValue={setCategory} />
        </div>
        <div className='createVSetContainer__insert'>
          <IconButton onClick={() => addTableRows()}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
        {!isValidCategoryData && <VSTypography size="lg">Please select at least two categories.</VSTypography>}
        {
          isValidCategoryData && (
            <div className="table-responsive">
              <table className="table table-responsive align-middle text-center table-bordered createVSetTable">
                  <thead>
                      <tr>
                          <th key="container-domain-head" className="createVSetTableHeaderFirst">
                              Domain
                          </th>
                          {category.map((category: any) => {
                              return (
                                  <th key={category.label} className="createVSetTableHeader">
                                      {category.label}
                                  </th>
                              );
                          })}
                      </tr>
                  </thead>
                  <tbody>
                    <TableRows
                        rowsData={rowsData}
                        deleteTableRows={deleteTableRows}
                        handleChange={handleChange}
                        headers={category}
                    />
                  </tbody>
              </table>
            </div>
          )
        }
    </div>
  )
});

export default CreateVset;