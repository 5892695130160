import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "pages/layouts/Footer";
import Header from "pages/layouts/navigation/NavHeader";
import { Outlet } from "react-router-dom";

const theme = createTheme({
    palette: {
        background: {
            default: "#F5F5F5",
        },
    },
});

export default function UserDashboardLayout() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="xl">
                <Header onScroll={() => {return;}} />
                <main>
                    <Outlet />
                </main>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}
