import Select from 'react-select';
import './Select.css';

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  option: (provided: any) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    textAlign: 'center',
    fontFamily: "font-family: 'Roboto', sans-serif;",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: '1px solid #c0c0c0',
    background: '#fff',
    display: 'flex',
    borderRadius: '7px',
    width: '100%',
    cursor: 'pointer',
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const ReactSelect = ({ data, setValue, defaultValue, label, isMulti=false }: any) => {
  console.log(defaultValue)
  const handleSelect = (e: any) => {
    setValue(e);
    console.log(e)
  };

  return (
    <Select
      className='ReactSelect'
      options={data}
      placeholder={<div>{label}</div>}
      isMulti={isMulti}
      menuPlacement='auto'
      menuPosition='absolute'
      value={
        defaultValue
      }
      styles={customStyles}
      onChange={handleSelect}
    />
  );
};

export default ReactSelect;
