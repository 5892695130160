import CollapsiblePanel from "../../../components/CollapsiblePanel/CollapsiblePanel";
import "./FAQs.css";

const FAQContent = [
    {
        no: 1,
        header: "What is VSorts™ AI?",
        content:
            "VSorts™ AI is a SaaS AI platform specifically designed for researchers, organizational leaders, and consultants. It streamlines the entire process of qualitative data handling, including collection, transcription, analysis, reporting, and storage. The platform targets critical challenges such as disconnected workflows, underutilized data, and delays in delivering valuable insights.",
    },
    {
        no: 2,
        header: "What are AI CoPilots?",
        content:
            "VSorts™ AI CoPilots, integrated within the VSorts™ SaaS platform, are advanced AI tools designed for efficient qualitative data analysis. Trained with expert input, they adeptly interpret complex data using frameworks like Cultural Proficiency©. These CoPilots seamlessly blend with user interfaces, providing real-time research assistance, feedback, and coaching. By automating labor-intensive tasks, they offer objective, nuanced insights, significantly reducing the time and cognitive load involved in processing qualitative data, such as open-ended surveys or audio/video transcriptions, thus enhancing the accuracy and efficiency of data analysis.",
    },
    {
        no: 3,
        header: "What is the Cultural Proficiency© Framework?",
        content:
            "The Cultural Proficiency© Framework is a model for organizations and individuals to interact effectively with different cultures. It provides guidelines and tools to assess and improve one's ability to value, conduct self-assessment, manage the dynamics of difference, institutionalize cultural knowledge, and adapt to diversity. The framework helps identify cultural barriers and biases, promoting a more inclusive and respectful environment. It's often used in educational and organizational settings to foster understanding, respect, and effective interactions among diverse cultural groups.",
    },
    {
        no: 4,
        header: "Who can benefit from using VSorts™ AI, and what are specific use cases across various industries?",
        content: (
            <div>
                <p>VSorts™ AI offers benefits across various sectors, including:</p>
                <ul>
                    <li>
                        Educational Sector: VSorts™ AI offers support in PreK-20 education for culturally aware
                        research, enhancing teaching methods, and improving classroom management, facilitating inclusive
                        learning environments.
                    </li>
                    <li>
                        Healthcare: VSorts™ AI offers healthcare professionals and researchers tools for patient data
                        analysis, contributing to improved patient care and cultural competence.
                    </li>
                    <li>
                        Human Resources: VSorts™ AI offers solutions for organizational culture analysis, employee
                        feedback interpretation, and diversity and inclusion initiatives.
                    </li>
                    <li>
                        Market Research: VSorts™ AI offers valuable insights into consumer feedback, cultural trends,
                        and customer experience.
                    </li>
                    <li>
                        Mental Health: VSorts™ AI offers therapists and counselors a deeper understanding of patient
                        narratives and cultural contexts in therapy.
                    </li>
                </ul>
                <p>
                    These use cases exemplify VSorts™ AI&apos;s capability to offer in-depth qualitative data analysis
                    and cultural insights across various sectors. Its versatility in addressing the unique needs of
                    different fields highlights its potential as a valuable tool in qualitative research and practice.
                    To learn more or explore how VSorts™ AI can assist your specific needs, scheduling a meeting is
                    recommended for a detailed discussion and demonstration.
                </p>
            </div>
        ),
    },
    {
        no: 5,
        header: "How are VSorts™ AI CoPilots trained? Are humans still involved?",
        content:
            "VSorts™ AI CoPilots are trained through advanced AI algorithms and human expertise. They are trained using extensive qualitative data sets, and human experts significantly contribute to their training process. This dual approach ensures that AI CoPilots accurately comprehend and interpret complex data with cultural sensitivity. Despite their advanced AI capabilities, human input is crucial for ensuring ongoing accuracy, maintaining cultural relevance, and enhancing the CoPilots' proficiency. This blend of AI and human expertise is critical to the CoPilots' effective functioning.",
    },
];

const FAQs: React.FC = () => {
    return (
        <div className="faqs">
            <div className="faqsContainer">
                <h1>Frequently Asked Questions</h1>
                {FAQContent.map((faq) => (
                    <CollapsiblePanel header={faq.header} content={faq.content} key={faq.no} />
                ))}
            </div>
        </div>
    );
};

export default FAQs;
