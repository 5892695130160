const styles = {
    button: {
        height: 42,
        padding: '10px 24px',
        borderRadius: 10,
        fontSize: 16,
        fontWeight: 500,
        borderWidth: 2,
        borderColor: '#000',
        background: '#fff',
        color: '#000',
    },
    primary: {
        borderColor: '#29ABE2',
        background: '#29ABE2',
        color: '#fff',
        '&:hover': {
            background: '#29ABE2',
            color: '#fff',
        },
    },
    secondary: {
        borderColor: '#94A8B0',
        border: '2px solid #94A8B0',
        background: '#fff',
        color: '#94A8B0',
        '&:hover': {
            background: '#fff',
            color: '#94A8B0',
        },
    },
    active: {
        borderColor: '#009245',
        border: '2px solid #009245',
        background: '#fff',
        color: '#009245',
        '&:hover': {
            background: '#fff',
            color: '#009245',
        },
    },
}

export default styles