import { useAuth0 } from "@auth0/auth0-react";
import SignUpButton from "pages/layouts/navigation/SignUpButton";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const SignUpPage = () => {
    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            const signUpBtn = document.getElementById("auth0-sign-up-button");
            if (signUpBtn) {
                signUpBtn.click();
            }
        }
    }, [isAuthenticated]);

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return <SignUpButton hide/>;
};

export default SignUpPage;
