const styles = {
    dropdown: {
        outlineWidth: 0, 
        borderRadius: "9px",
         border: "none", 
         padding: "0",
         '& .MuiSelect-select': {
            padding: "0",
            paddingLeft: "15px",
         },
    }
}

export default styles