import { Box, CssBaseline, IconButton } from "@mui/material";
import EyeIcon from "assets/icons/eye.svg";
import TrashIcon from "assets/icons/trash-red.svg";
import Header from "components/Dashboard/Header";
import { Button, Tabs } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import "./CreateVSets.css";
import styles from "./styles";

interface tab {
    name: string;
    url: string;
}

const tabs: tab[] = [
    {
        name: "VSet",
        url: "/v2/admin/vsets/create",
    },
    {
        name: "Parameters",
        url: "/v2/admin/vsets/create/parameters",
    },
    {
        name: "Unpacking",
        url: "/v2/admin/vsets/create/unpacking",
    },
    {
        name: "Assign",
        url: "/v2/admin/vsets/create/assign",
    },
];

const CreateVsetLayout: React.FC = () => {
    const childRef = useRef<any>();
    const [activeTab, setActiveTab] = useState<tab>({
        name: "",
        url: "",
    });

    //const vsetContainer = useSelector(CurrentCreateVsetSelector);
    const location = useLocation();
    const navigate = useNavigate();

    //const { title } = useParams();
    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    const id = searchParams.get("id");

    const onTabSelect = (tab: tab) => {
        setActiveTab(tab);
        navigate(`${tab.url}?title=${title}&id=${id}`);
    };

    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.save === "function") {
                console.log("saved")
                childRef.current.save();
            }
        }
    };

    useEffect(() => {
        const tab: tab = tabs.find((tab) => location.pathname == tab.url) || {
            name: "",
            url: "",
        };

        setActiveTab(tab);
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header />
            <AdminHeader
                title="Admin create vset"
                onIconClick={() => {
                    console.log();
                }}
            />

            <Box sx={styles.container}>
                <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={EyeIcon} alt="view" />
                </IconButton>

                <input className="titleInput" value={title ?? ""} disabled />

                <Button type="primary" text="Save" onClick={() => handleSaveClick()} />

                <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={TrashIcon} alt="Delete" />
                </IconButton>
            </Box>

            <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} onTabSelect={onTabSelect} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 20 }}>
                <Outlet context={{ childRef }} />
            </Box>
        </Box>
    );
};

export default CreateVsetLayout;
