import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, MenuItem, Modal, Select, SelectChangeEvent, Typography } from "@mui/material";
import DatePickerComponent from "components/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { DemographicRequestBody, DemographicsData, DemographicsOptions } from "types/dashboard";
import styles from "./styles";

type PropsType = {
    open: boolean;
    options: DemographicsOptions;
    data: DemographicsData | null;
    onClose: () => void;
    onSave: (data: DemographicRequestBody) => void;
};

const DemographicsModal: React.FC<PropsType> = ({ open, data, options, onClose, onSave }) => {
    const [country, setCountry] = useState<number>(-1);
    const [state, setState] = useState<number>(-1);
    const [race, setRace] = useState<number>(-1);
    const [gender, setGender] = useState<number>(-1);
    const [dob, setDob] = useState<Dayjs | null>(null);
    const [relationShipStatus, setRelationShipStatus] = useState<number>(-1);
    const [sex, setSex] = useState<number>(-1);
    const [education, setEducation] = useState<number>(-1);
    const [income, setIncome] = useState<number>(-1);
    const [politicalOrientation, setPoliticalOrientation] = useState<number>(-1);

    React.useEffect(() => {
        if (data) {
            setCountry(data.country_id);
            setState(data.state_id);
            setRace(data.race_id);
            setGender(data.gender_id);
            setDob(dayjs(data.date_of_birth));
            setRelationShipStatus(data.relationship_status_id);
            setSex(data.sexual_orientation_id);
            setEducation(data.education_id);
            setIncome(data.income_id);
            setPoliticalOrientation(data.political_orientation_id);
        }
    }, [data])

    const handleCountryChange = (event: SelectChangeEvent) => {
        setCountry(parseInt(event.target.value, 10));
    };

    const handleStateChange = (event: SelectChangeEvent) => {
        setState(parseInt(event.target.value, 10));
    };

    const handleRaceChange = (event: SelectChangeEvent) => {
        setRace(parseInt(event.target.value, 10));
    };

    const handleRelationShipStatusChange = (event: SelectChangeEvent) => {
        setRelationShipStatus(parseInt(event.target.value, 10));
    };

    const handleGenderChange = (event: SelectChangeEvent) => {
        setGender(parseInt(event.target.value, 10));
    };

    const handleSexChange = (event: SelectChangeEvent) => {
        setSex(parseInt(event.target.value, 10));
    };

    const handleEducationChange = (event: SelectChangeEvent) => {
        setEducation(parseInt(event.target.value, 10));
    };

    const handleIncomeChange = (event: SelectChangeEvent) => {
        setIncome(parseInt(event.target.value, 10));
    };

    const handlePoliticalOrientationChange = (event: SelectChangeEvent) => {
        setPoliticalOrientation(parseInt(event.target.value, 10));
    };

    const onSaveDemographics = () => {
        const demographicsData: DemographicRequestBody = {
            country,
            state,
            race,
            gender,
            date_of_birth: dob?.toDate() as Date,
            relationship_status: relationShipStatus,
            sexual_orientation: sex,
            education,
            income,
            political_orientation: politicalOrientation,
        };
        onSave(demographicsData);
    }

    return (
        <Modal open={open} onClose={onClose} sx={styles.modal}>
            <Box sx={styles.container}>
                <Typography sx={styles.title}>Edit Demographics</Typography>
                <CloseIcon sx={styles.closeIcon} onClick={onClose} />
                <Box sx={styles.flexContainer}>
                    <Box sx={styles.halfContainer}>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Country</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={country?.toString()}
                                // label="Country"
                                onChange={handleCountryChange}
                                sx={styles.select}
                            >
                                {options.countries.map((country) => (
                                    <MenuItem value={country.id} key={country.id}>
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Race/Ethnicity</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={race?.toString()}
                                // label="Race/Ethnicity"
                                sx={styles.select}
                                onChange={handleRaceChange}
                            >
                                {options.races.map((race) => (
                                    <MenuItem value={race.id} key={race.id}>
                                        {race.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Date of Birth</Typography>
                            {/* <Typography sx={styles.text}> */}
                            {/* {data?.date_of_birth || "N/A"} */}
                            <DatePickerComponent value={dob} setValue={setDob} defaultValue={dob} />
                            {/* </Typography> */}
                        </Box>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Sex</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={sex?.toString()}
                                // label="Sex"
                                sx={styles.select}
                                onChange={handleSexChange}
                            >
                                {options.sexualOrientations.map((sexualOrientation) => (
                                    <MenuItem value={sexualOrientation.id} key={sexualOrientation.id}>
                                        {sexualOrientation.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Income</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={income?.toString()}
                                // label="Age"
                                sx={styles.select}
                                onChange={handleIncomeChange}
                            >
                                {options.incomes.map((income) => (
                                    <MenuItem value={income.id} key={income.id}>
                                        {`${income.currency_symbol} ${income.min_income}-${income.currency_symbol} ${
                                            income.max_income || "MAX"
                                        }`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    <Box sx={styles.halfContainer}>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>State</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={country === 230 ? state?.toString() : ""}
                                label="Age"
                                sx={styles.select}
                                disabled={country !== 230}
                                onChange={handleStateChange}
                            >
                                {options.states.map((state) => (
                                    <MenuItem value={state.id} key={state.id}>
                                        {state.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Gender</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={gender?.toString()}
                                // label="Gender"
                                sx={styles.select}
                                onChange={handleGenderChange}
                            >
                                {options.genders.map((gender) => (
                                    <MenuItem value={gender.id} key={gender.id}>
                                        {gender.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Relationship Status</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={relationShipStatus?.toString()}
                                // label="Age"
                                sx={styles.select}
                                onChange={handleRelationShipStatusChange}
                            >
                                {options.relationshipStatuses.map((relationship) => (
                                    <MenuItem value={relationship.id} key={relationship.id}>
                                        {relationship.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Education</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={education?.toString()}
                                // label="Age"
                                sx={styles.select}
                                onChange={handleEducationChange}
                            >
                                {options.educations.map((education) => (
                                    <MenuItem value={education.id} key={education.id}>
                                        {education.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box sx={styles.item}>
                            <Typography sx={styles.textGray}>Political Orientation</Typography>
                            <Select
                                labelId="label"
                                id="select"
                                value={politicalOrientation?.toString()}
                                // label="Age"
                                sx={styles.select}
                                onChange={handlePoliticalOrientationChange}
                            >
                                {options.politicalOrientations.map((politicalOrientation) => (
                                    <MenuItem value={politicalOrientation.id} key={politicalOrientation.id}>
                                        {politicalOrientation.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                    <Button
                        sx={{
                            border: "1px solid #929292",
                            borderRadius: "30px",
                            color: "#929292",
                            width: "134px",
                            height: "42px",
                        }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            background: " #29ABE2",
                            border: "1px solid #29ABE2",
                            borderRadius: "30px",
                            color: "#fff",
                            width: "134px",
                            height: "42px",
                        }}
                        onClick={onSaveDemographics}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DemographicsModal;
