const styles: any = {
    container: {
        background: '#fff',
        borderRadius: 10,
        padding: '30px 30px 30px',
        minHeight: 'max-content',
        width: "100%"
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    adminBtn: {
        color: '#94A8B0',
        borderColor: '#94A8B0',
        borderRadius: '25px',
        textTransform: 'none',
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: '#000',
    },
    userInfo: {
        marginTop: 16,
        marginBottom: 40,
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        width: 90,
        height: 90,
        borderRadius: '50%',
        marginRight: 16,
    },
    text: {
        fontSize: 14,
        color: '#000',
    },
    role: {
        marginTop: 6,
        marginBottom: 6,
    },
    textGray: {
        fontSize: 14,
        color: 'rgb(100, 116, 139)',
    },
    infoIcon: {
        height: 'fit-content',
        verticalAlign: 'top',
        width: '18%',
        maxWidth: '20px',
    },
    status: {
        padding: '15px 17px',
        borderRadius: 10,
        border: '1px solid #DBE6ED',
        marginBottom: 16,
        width: '31%', 
    },
    statusHeader: {
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between",
    },
    statusGreen: {
        borderColor: '#009245',
        background: '#EAFFF4',
    },
    statusValue: {
        fontWeight: 500,
        fontSize: 24,
        color: '#000',
        marginTop: 3,
    },
    colorGreen: {
        color: '#009245',
    },
    organizationContainer: {
        width: '50%',
        padding: '8px 11px',
    },
    groupContainer: {
        width: '50%',
        borderRadius: 10,
        background: '#F0FBFF',
        padding: '8px 11px',
    },
    btnViewDemographics: {
        marginTop: '2rem',
        width: '100%',
        padding: '14px 0',
        fontWeight: 500,
        fontSize: 16,
        color: '#FFFFFF',
        backgroundColor: '#29ABE2',
        textTransform: 'none',
        borderRadius: 10,
        border: '1px solid #DBE6ED',
        "&:hover": {
            color: "#29ABE2"
        },
    },
    profile: {
        display: 'flex',
        gap: '10px'
    }
}

export default styles