const styles: any = {
    container: {
      background: '#fff',
      borderRadius: 10,
      padding: '30px 15px 30px 30px',
      minHeight: "max-content",
      transition: 'all 1s ease-in-out',
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 450,
        overflowY: 'auto',
        gap: '1.5rem'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        color: '#929292',
    },
}

export default styles;