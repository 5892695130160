import { Button as MUIButton } from '@mui/material';
import { CSSProperties } from 'react';
import styles from './styles';

export type PropsType = {
    text: string,
    type: 'primary' | 'secondary' | 'normal' | 'active',
    style?: CSSProperties,
    onClick: () => void,
}

const Button = ({ text, type, style = {}, onClick }: PropsType) => {
    return (
        <MUIButton 
            type="button" 
            sx={[
                style,
                styles.button,
                type === 'primary' && styles.primary,
                type ==='secondary' && styles.secondary,
                type === 'active' && styles.active
            ]}
            onClick={onClick}
        >
            {text}
        </MUIButton>
    )
}

export default Button;