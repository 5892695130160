const styles: any = {
    outerCard: {
        minWidth: "80vw",
        maxWidth: "80vw",
        left: "auto",
        right: "auto",
        backgroundColor: "#ffffff",
        padding: "15px",
        borderRadius: "15px",
    },
    completionStatus: { color: "#929292", lineHeight: 0.8 },
    unpackingCompletionText: {
        fontSize: "16px",
        color: "#929292",
        textAlign: "right",
        paddingRight: "2vw",
        paddingBottom: "1vh",
    },
    speechIcon: {
        color: "green",
        position: "relative",
        marginTop: "-200px",
    },
    radioButtonOptions: {
        border: "1px solid #f5f8fb",
        background: "#f5f8fb",
        color: "black",
        borderRadius: "32px",
        margin: "10px",
        paddingLeft: "2%",
        minWidth: "60vw",
    },
    iconButton: { color: "white", background: "#246480" },
};

export default styles;
