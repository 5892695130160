import MoreIcon from "@mui/icons-material/More";
import PollIcon from "@mui/icons-material/Poll";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SortIcon from "@mui/icons-material/Sort";
import { Box, Step, Tooltip, Typography } from "@mui/material";
import CircularLoadingProgress from "components/CircularProgress";
import { useUserClusterStepData } from "hooks/VsortsClusterFormData";
import React, { useState } from "react";
import { Cluster, ClusterStepData } from "types/dashboard";
import { ClusterUser } from "types/user";
import styles from "./styles";

import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

import { styled } from "@mui/material/styles";

import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

type PropsType = {
    isAdmin: boolean;
    cluster: Cluster | null;
    user: ClusterUser | null;
};

const ColorlibStepIconRoot: any = styled("div")(({ theme, ownerState }: any) => ({
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundImage:
            "linear-gradient( 136deg, rgb(46, 170, 250) 0%, rgb(1, 191, 253) 50%, rgb(109, 161, 252) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage:
            "linear-gradient( 136deg, rgb(46, 170, 250) 0%, rgb(1, 191, 253) 50%, rgb(109, 161, 252) 100%)",
    }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                "linear-gradient( 95deg,rgb(46, 170, 250) 0%,rgb(1, 191, 253) 50%,rgb(109, 161, 252) 100%)",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                "linear-gradient( 95deg,rgb(46, 170, 250) 0%,rgb(1, 191, 253) 50%,rgb(109, 161, 252) 100%)",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
}));

function ColorlibStepIcon(activity: any) {
    const completed = activity?.completed;
    const active = false;
    const icons: any = {
        presurvey: <PollIcon />,
        postsurvey: <PollIcon />,
        vsorts: <SortIcon />,
        unpacking: <QuestionAnswerIcon />,
        finalfeedback: <MoreIcon />,
    };

    return <ColorlibStepIconRoot ownerState={{ completed, active }}>{icons[activity?.form_type]}</ColorlibStepIconRoot>;
}

const typeName = (type: string) => {
    switch (type) {
        case "presurvey":
            return "Survey";
        case "postsurvey":
            return "Survey";
        case "vsorts":
            return "VSorts";
        case "unpacking":
            return "Unpacking";
        case "finalfeedback":
            return "Survey";
        default:
            return "V-Sorts";
    }
};
const ClusterCharts: React.FC<PropsType> = ({ isAdmin, cluster, user }) => {
    const [isData, setIsData] = useState<boolean>(false);
    const [clusterStepsData, setClusterStepsData] = useState<any[]>([]);

    const onVSortsClusterStepsFetchSuccess = (stepData: ClusterStepData[]) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        setClusterStepsData(stepData);
        setIsData(true);
    };

    const {
        isFetched,
        isFetching: isVSortsClusterStepsDataFetching,
        isLoading: isVSortsClusterStepsDataLoading,
        isError: isVSortsClusterStepsDataError,
        data: VSortsClusterStepsData,
        error: VSortsClusterStepsDataError,
        refetch: refetchVSortsClusterSteps,
    } = useUserClusterStepData({
        isAdmin,
        clusterID: cluster?.id,
        userID: user?.id,
        onSuccess: onVSortsClusterStepsFetchSuccess,
    });

    if (isFetched && !isData && VSortsClusterStepsData) {
        onVSortsClusterStepsFetchSuccess(VSortsClusterStepsData);
    }

    if (cluster && isVSortsClusterStepsDataLoading) {
        return (
            <Box sx={styles.container}>
                <CircularLoadingProgress />
            </Box>
        );
    }

    if (isAdmin && (!cluster || !user)) {
        return (
            <Box sx={styles.container}>
                <Typography sx={styles.title}>{cluster === null ? "VSorts™ Clusters" : cluster.title}</Typography>
                <Typography sx={styles.warning}>{"Please select cluster and user to see the data."}</Typography>
            </Box>
        );
    }

    const activeStepFunction: any = () => {
        let activeIndex = -1;
        for (let index = 0; index < clusterStepsData?.length; index++) {
            if (clusterStepsData[index].completed == 0) {
                activeIndex = index;
                break;
            }
        }
        return activeIndex - 1;
    };

    return (
        <Box sx={styles.container}>
            <Typography sx={styles.title}>
                {cluster === null ? "VSorts™ Clusters" : cluster.title + " Status"}
            </Typography>
            {cluster && (
                <Box sx={styles.chartContainer}>
                    <Stepper
                        style={{
                            paddingTop: "8%",
                        }}
                        alternativeLabel
                        activeStep={activeStepFunction()}
                        connector={<ColorlibConnector />}
                    >
                        {clusterStepsData?.map((eachActivity: any) => (
                            <Tooltip title={eachActivity?.container_name}>
                                <Step key={eachActivity?.form_type}>
                                    <StepLabel StepIconComponent={() => ColorlibStepIcon(eachActivity)}>
                                        {typeName(eachActivity?.form_type)}
                                    </StepLabel>
                                </Step>
                            </Tooltip>
                        ))}
                    </Stepper>
                </Box>
            )}
            {cluster && <Typography sx={styles.xAxisTitle}>status</Typography>}
            {cluster && <Typography sx={styles.yAxisTitle}>activities</Typography>}
        </Box>
    );
};

export default ClusterCharts;
