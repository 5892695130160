import { Box, Button, Typography } from "@mui/material";
import FunctionIcon from "assets/icons/function.svg";
import GridIcon from "assets/icons/grid.svg";
import InfoIcon from "assets/icons/info.svg";
import { useUserAssignedClusters } from "hooks/VSortsClusterData";
import { getUserProfile } from "hooks/userData";
import React, { useEffect, useState } from "react";
import { Cluster, ViewType } from "types/dashboard";
import { ClusterUser, User, UserRole } from "types/user";
import ClusterCharts from "./ClusterChart";
import ClusterUsers from "./ClusterUsers";
import Clusters from "./Clusters";
import UserProfile from "./UserProfile";
import styles from "./styles";

import { CurrentUserSelector } from "app/slices/UserSlice";
import { USER_GROUP, USER_ORGANIZATION, USER_STATUS } from "mock/dashboard";
import { useSelector } from "react-redux";
import { getUserRole } from "utils/userHelper";
import ClusterFilter from "./ClusterFilter";

const Dashboard: React.FC = () => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const user = useSelector(CurrentUserSelector);
    const [viewType, setViewType] = useState<ViewType>(ViewType.VIEW_MODE_LIST);
    const [clusters, setClusters] = useState<Cluster[]>([]);
    const [selectedCluster, setSelectedCluster] = useState<Cluster | null>(null);
    const [selectedClusterUser, setSelectedClusterUser] = useState<ClusterUser | null>(null);
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    const {
        isLoading: isAllVSortsClusterDataLoading,
        isError: isAllVSortsClusterDataError,
        data: allClusters,
        error,
        refetch: refetchAllVSortsContainerData,
    } = useUserAssignedClusters({});

    if (allClusters) {
        if (allClusters.length && !clusters.length) {
            setClusters(allClusters);
        }
    }

    const onUserDataFetchSuccess = (data: any) => {
        setCurrentUser({
            id: (data?.user_id as string) || "",
            name: (data?.name as string) || "",
            nickname: (data?.nickname as string) || "",
            img: data?.picture || user?.picture || "",
            email: (data?.email as string) || (user?.email as string) || "",
            first_name: (data?.first_name as string) || "",
            last_name: (data?.last_name as string) || "",
            role: getUserRole(data?.roles as string[]),
        });
    };

    const onUserDataFetchError = (error: any) => {
        setCurrentUser(null);
    };

    const {
        isFetching: isProfileDataFetching,
        isLoading: isProfileDataLoading,
        isError: isProfileDataError,
        data: profileData,
        error: profileDataError,
        refetch: refetchUserProfile,
    } = getUserProfile({
        onSuccess: onUserDataFetchSuccess,
        onError: onUserDataFetchError,
    });

    // const fetchUserProfile = async () => {
    //     try {
    //         const res: any = await getUserProfile();
    //         const data: any = res.data.data;
    //         setCurrentUser({
    //             id: (data.user_id as string) || "",
    //             name: (data.name as string) || "",
    //             nickname: (data.nickname as string) || "",
    //             img: (data.picture as string) || (user?.picture as string) || "",
    //             email: (data.email as string) || "",
    //             first_name: (data.first_name as string) || "",
    //             last_name: (data.last_name as string) || "",
    //             role: getUserRole(data.roles as string[]),
    //         });
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    const onSwitchMode = (type: ViewType) => {
        setViewType(type);
    };

    const onSelectCluster = (cluster: Cluster) => {
        setSelectedCluster(cluster);
    };

    const onSelectClusterUser = (user: ClusterUser) => {
        setSelectedClusterUser(user);
    };

    useEffect(() => {
        // fetchUserProfile();
        refetchUserProfile();
    }, []);

    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleContainer}>
                <Typography sx={styles.title}>Dashboard</Typography>
                <Box sx={styles.flexContainer}>
                    <Button sx={styles.btnAction} onClick={() => onSwitchMode(ViewType.VIEW_MODE_LIST)}>
                        <img src={FunctionIcon} alt="#" style={styles.actionIcon} />
                    </Button>
                    <Button sx={styles.btnAction} onClick={() => onSwitchMode(ViewType.VIEW_MODE_GRID)}>
                        <img src={GridIcon} alt="#" style={styles.actionIcon} />
                    </Button>
                </Box>
            </Box>
            <Box sx={styles.contentContainer}>
                <Box sx={styles.mainSection}>
                    <Box sx={styles.contentContainer}>
                        <Box sx={styles.profileContainer}>
                            <Box sx={styles.profile}>
                                <UserProfile
                                    user={currentUser}
                                    status={USER_STATUS}
                                    organization={USER_ORGANIZATION}
                                    group={USER_GROUP}
                                    viewType={viewType}
                                />
                                {viewType === ViewType.VIEW_MODE_GRID && (
                                    <Box sx={{ ...styles.userValues, ...styles.dashboardStatus }}>
                                        <Box sx={{ ...styles.status, ...styles.dashboardStatusCard }}>
                                            {/* <img src={VignettesIcon} alt="i" style={styles.statusIcon} /> */}
                                            <Box sx={styles.statusHeader}>
                                                <Typography sx={styles.statusTitle}>
                                                    Flagged
                                                    <br /> Vignettes
                                                </Typography>
                                                <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                                            </Box>
                                            <Typography sx={styles.statusValue}>
                                                {USER_STATUS.flaggedVignettes}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ...styles.status, ...styles.dashboardStatusCard }}>
                                            {/* <img src={UnpackedIcon} alt="i" style={styles.statusIcon} /> */}
                                            <Box sx={styles.statusHeader}>
                                                <Typography sx={styles.statusTitle}>
                                                    Unpacked
                                                    <br /> Vignettes
                                                </Typography>
                                                <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                                            </Box>
                                            <Typography sx={styles.statusValue}>
                                                {USER_STATUS.unpackedVignettes}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                ...styles.status,
                                                ...styles.statusGreen,
                                                ...styles.dashboardStatusCard,
                                            }}
                                        >
                                            {/* <img src={CulturalIcon} alt="i" style={styles.statusIcon} /> */}
                                            <Box sx={styles.statusTitle}>
                                                <Typography sx={{ ...styles.statusTitle, ...styles.colorGreen }}>
                                                    Cultural
                                                    <br /> Profiency
                                                </Typography>
                                                <img src={InfoIcon} alt="i" style={styles.infoIcon} />
                                            </Box>
                                            <Typography sx={{ ...styles.statusValue, ...styles.colorGreen }}>
                                                {USER_STATUS.culturalProfiency}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Box sx={styles.dasboardReport}></Box>
                        </Box>
                        <Box sx={styles.clusters}>
                            <Box sx={styles.flex}>
                                <Clusters
                                    currentUser={currentUser}
                                    isLoading={isAllVSortsClusterDataLoading}
                                    clusters={clusters}
                                    onSelectCluster={onSelectCluster}
                                    setOpenFilter={setOpenFilter}
                                />
                                {openFilter && <ClusterFilter />}
                            </Box>
                            {/* {viewType === ViewType.VIEW_MODE_GRID && ( */}
                            <Box sx={styles.flexContainer}>
                                <Box sx={styles.halfContainer}>
                                    {selectedCluster != null && (
                                        <ClusterCharts
                                            isAdmin={currentUser?.role === UserRole.ADMIN}
                                            cluster={selectedCluster}
                                            user={selectedClusterUser}
                                        />
                                    )}
                                </Box>
                                {currentUser?.role === UserRole.ADMIN && (
                                    <Box sx={styles.halfContainer}>
                                        <ClusterUsers cluster={selectedCluster} onSelectUser={onSelectClusterUser} />
                                    </Box>
                                )}
                            </Box>
                            {/* )} */}
                        </Box>
                    </Box>
                    {/* {viewType === ViewType.VIEW_MODE_LIST && (
                        <Box sx={styles.flexContainer}>
                            <Box sx={styles.halfContainer}>
                                <ClusterCharts 
                                    isAdmin={currentUser?.role === UserRole.ADMIN} 
                                    cluster={selectedCluster} 
                                    user={selectedClusterUser} 
                                />
                            </Box>
                            {currentUser?.role === UserRole.ADMIN && (
                                <Box sx={styles.halfContainer}>
                                    <ClusterUsers
                                        cluster={selectedCluster}
                                        onSelectUser={onSelectClusterUser}
                                    />
                                </Box>
                            )}
                        </Box>
                    )} */}
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
