import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Global CSS Styles
import GlobalCSS from "global.css";
import "./index.css";

import App from "App";

// Redux Imports
import { store } from "app/store";
import { Provider } from "react-redux";

import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from "serviceWorker";
ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        // domain='dev-asjxsmk1.us.auth0.com'
        // clientId='jkWxULphXWdd3cxYJa0usdrW2itlSFch'
        // redirectUri={window.location.origin}
    >
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <GlobalCSS />
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    </Auth0Provider>,

    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
