import { COLOR } from "constants/color.constants";

const styles: any = {
    dashboardLayoutContainer: {
        padding: "0 80px",
        background: COLOR.background,
        height: "100%",
    },
    container: {
        // padding: "0",
        background: COLOR.background,
    },
    flexContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    flex: {
        display: "flex",
        gap: "10px",
        width: "100%",
        flex: 6,
        transition: "all 1s ease-in-out",
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20,
    },
    title: {
        fontWeight: 500,
        fontSize: 24,
        color: "#000",
    },
    btnAction: {
        border: "none",
        marginLeft: 16,
        padding: 0,
        minWidth: "unset",
    },
    actionIcon: {
        width: 18,
        height: 18,
    },
    contentContainer: {
        display: "flex",
        gap: "1rem",
    },
    profileContainer: {
        width: "100%",
        flex: 4,
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
    },
    profile: {
        width: "100%",
        // height: "100%",
        display: "flex",
        gap: "1rem",
    },
    clusters: {
        flex: 6,
        height: "max-content",
    },
    demographics: {
        flex: 4,
    },
    charts: {
        flex: 6,
    },
    userValues: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "1rem",
    },
    mainSection: {
        flex: 10,
    },
    status: {
        padding: "29px 23px",
        borderRadius: 10,
        background: "#fff",
        border: "1px solid #E6E8F1",
        position: "relative",
    },
    dashboardStatus: {
        flex: "1 !important",
    },
    dashboardStatusCard: {
        padding: "0.8rem 3rem 1rem 1rem !important",
    },
    statusHeader: {},
    infoIcon: {
        position: "absolute",
        top: "10px",
        right: "10px",
    },
    text: {
        fontSize: 14,
        color: "#000",
    },
    statusTitle: {
        fontSize: 16,
        lineHeight: "24px",
        color: "#929292",
        marginBottom: 6,
    },
    statusGreen: {
        border: "1px solid #009245",
        background: "#EAFFF4",
    },
    statusValue: {
        fontWeight: 500,
        fontSize: 24,
        color: "#000",
        marginTop: 1,
    },
    colorGreen: {
        color: "#009245",
    },
    statusIcon: {
        width: 22,
        height: 22,
        marginBottom: 16,
    },
    halfContainer: {
        flex: 5,
    },
    dasboardReport: {
        background: "#fff",
        height: "100%",
        width: "100%",
        borderRadius: 10,
    },
};

export default styles;
