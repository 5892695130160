import Header from "components/Dashboard/Header";
import Footer from "components/Footer";

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import styles from "./styles";

export default function DashboardLayout() {
    return (
        <Box sx={styles.dashboardLayoutContainer}>
            <Header />
            <Outlet />
            <Footer />
        </Box>
    );
}
