import { Box, Typography } from '@mui/material'
import React from 'react'
import { Cluster } from 'types/dashboard'
import { ClusterUser } from 'types/user'
import { getUserName } from 'utils/userHelper'
import styles from './styles'

type PropsType = {
    cluster: Cluster | null
    onSelectUser: (user: ClusterUser) => void
}

const ClusterUsers: React.FC<PropsType> = ({ cluster, onSelectUser }) => {
    return (
        <Box sx={styles.container}>
            <Typography sx={styles.title}>
                VSorts™ Users
            </Typography>
            <Box sx={styles.users}>
                {cluster?.users.map(user => (
                    <Box sx={styles.user} key={user.id} onClick={() => onSelectUser(user)}>
                        <Box sx={styles.userInfo}>
                            <Typography sx={styles.name}>
                                {getUserName(user)}
                            </Typography>
                            <Typography sx={styles.score}>
                                10 
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default ClusterUsers