// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-enterprise";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Grid } from "@mui/material";
import {
    MaximumUnpackingCountSelector,
    OpenDialogSelector,
    setHeatMapView,
    setOpenDialog,
    UnpackedVignetteCountSelector,
} from "app/slices/UnpackingSlice";
import VSBox from "components/VSBox";
import VSButton from "components/VSButton";
import VSCard from "components/VSCard/VSCard";
import VSCardActions from "components/VSCard/VSCardActions";
import VSCardContent from "components/VSCard/VSCardContent";
import VSTooltip from "components/VSTooltip";
import VSTypography from "components/VSTypography";
import { useDispatch, useSelector } from "react-redux";
import SimpleDialog from "../UnpackingQuestions";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    textAlign: "left",
    minHeight: "15vh",
    maxHeight: "15vh",
    overflow: "hidden",
    color: theme.palette.text.secondary,
    cursor: "pointer",
}));

const ViewVSetResponses = ({
    data,
    container_id,
    cluster_id,
    user_id,
    cluster_step_id,
    refetchVSetsResponsesData,
}: any) => {
    const navigate = useNavigate();
    const { clusterID } = useParams();
    const openUnpacking = useSelector(OpenDialogSelector);
    const maximumUnpackingCount = useSelector(MaximumUnpackingCountSelector);
    const unpackedVignetteCount = useSelector(UnpackedVignetteCountSelector);
    const [vignetteSelected, setVignetteSelected] = useState<any>();
    const dispatch = useDispatch();

    const [flaggedVignettes, setFlaggedVignettes] = useState([]); // Set data to Array of Objects, one Domain per Row

    useEffect(() => {
        setFlaggedVignettes(data);
    }, []);

    const openUnpackingDialog = (vignette: any) => {
        if (vignette?.unpacked == 0 && unpackedVignetteCount < maximumUnpackingCount) {
            setVignetteSelected(vignette);
            dispatch(setOpenDialog(true));
        }
    };

    const navigateToAllVignetteResponsesPage = () => {
        dispatch(setHeatMapView(true));
    };

    const refetchFlaggedData = () => {
        refetchVSetsResponsesData();
    };

    return (
        <>
            <VSCard sx={{ minWidth: 275 }}>
                <VSCardContent>
                    <VSTypography variant="h1">Unpacking your reactions/Flagged Vignettes</VSTypography>
                    <VSTypography sx={{ fontSize: 14 }} gutterBottom>
                        <b>Directions: </b>Each bolded number represents an opportunity for you to unpack your reaction
                        to the vignette and the corresponding culturally proficient interaction. Above each column is a
                        link to the definition for each culturally proficient interaction; review the definitions before
                        unpacking your reactions. After, begin unpacking your reactions by clicking each of the items
                        highlighted yellow. You can also click other bolded items not highlighted yellow if you wish to
                        unpack additional vignettes.
                    </VSTypography>
                    <VSBox sx={{ width: "100%" }}>
                        {flaggedVignettes?.map((eachDomain: any) => {
                            return (
                                <>
                                    <VSTypography
                                        variant="h5"
                                        component="div"
                                        style={{ marginTop: "5vh", marginBottom: "3vh" }}
                                    >
                                        <b>Domain: </b> {eachDomain["domain_name"]}
                                    </VSTypography>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        {eachDomain["vignettes"].map((eachVignette: any) => {
                                            return (
                                                <VSTooltip title={eachVignette["vignette_text"]}>
                                                    <Grid item xs={3} onClick={() => openUnpackingDialog(eachVignette)}>
                                                        <Item
                                                            style={{
                                                                background: eachVignette["unpacked"] ? "green" : "",
                                                                color: eachVignette["unpacked"] ? "white" : "",
                                                            }}
                                                        >
                                                            {eachVignette["vignette_text"]}
                                                        </Item>
                                                    </Grid>
                                                </VSTooltip>
                                            );
                                        })}
                                    </Grid>
                                    <hr></hr>
                                </>
                            );
                        })}
                    </VSBox>
                </VSCardContent>
                <VSCardActions>
                    <VSButton onClick={() => navigateToAllVignetteResponsesPage()}>See HeatMap</VSButton>
                </VSCardActions>
            </VSCard>
            <div>
                {openUnpacking && (
                    <SimpleDialog
                        selectedValue={openUnpacking}
                        vignette_id={vignetteSelected?.id}
                        vignette_text={vignetteSelected?.vignette_text}
                        vignette_rank_category={vignetteSelected?.user_rank_category}
                        open={openUnpacking}
                        container_id={container_id}
                        cluster_id={cluster_id}
                        user_id={user_id}
                        cluster_step_id={cluster_step_id}
                        onClose={openUnpackingDialog}
                        refetchFlaggedData={refetchFlaggedData}
                    />
                )}
            </div>
        </>
    );
};

export default ViewVSetResponses;
