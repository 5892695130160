// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Material Imports
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import DatePicker from "@material-ui/lab/DatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import TimePicker from "@material-ui/lab/TimePicker";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// Form Elements
import VSAlert from "components/VSAlert";
import VSBox from "components/VSBox";
import VSPaper from "components/VSPaper";
import VSTypography from "components/VSTypography";

// Constants
import { CustomConstants } from "constants/custom.constants";

// `ViewElements` is the function to show the user the Form Elements in Viewer Mode
// 1. index -> Represents the current index in the list of Form Elements
// 2. item -> Represents the Form Item Object (Consisting of all properties)
// 3. dropdownOption -> Represents the current drop-down option selected in the Form Element
// 4. handleDropdownChange -> Represents the function to handle the change in the selected drop-down option in the Form Element
// 5. handleDate -> Represents the date selected for the Date option in the Form Element
// 6. handleTime -> Represents the time selected for the Time option in the Form Element
// 7. usaDropdownOption -> Represents the currently selected state option in the USA State Form Element
// 8. handleUSADropdownChange -> Represents the function to handle the change in the selected drop-down option in the USA State Form Element
export const ViewElements = ({
    index,
    item,
    dropdownOption,
    handleDropdownChange,
    handleDate,
    handleTime,
    usaDropdownOption,
    handleUSADropdownChange,
    totalSection,
}: any) => {
    const hasMultipleSection = totalSection > 1;
    switch (item.answer_type) {
        case "section":
            return (
                <>
                    <VSPaper key={index + item.id}>
                        <VSAlert
                            icon={false}
                            severity="info"
                            sx={(theme) => ({
                                color: "#fff",
                                background: theme.palette.secondary.main,
                                height: hasMultipleSection ? "auto" : "0.5rem",
                            })}
                        >
                            {hasMultipleSection && `Section ${index + 1} of ${totalSection}`}
                        </VSAlert>
                        <VSBox sx={{ p: 2 }}>
                            <VSTypography size="lg">{item.section_title}</VSTypography>
                            <VSTypography>{item.description}</VSTypography>
                        </VSBox>
                    </VSPaper>
                </>
            );
        case "text":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <TextField fullWidth label={item.question_text} />
                </Box>
            );
        case "textarea":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <TextField fullWidth label={item.question_text} multiline rows={3} />
                </Box>
            );
        case "header_info":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <VSTypography>{item.question_text}</VSTypography>
                </Box>
            );
        case "number":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <TextField fullWidth label={item.question_text} type="number" />
                </Box>
            );
        case "radio":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">{item.question_text}</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            {item.answer_options.map((individualItem: any, index: number) => {
                                return (
                                    <FormControlLabel
                                        key={index + individualItem.id}
                                        value={individualItem.value}
                                        control={<Radio />}
                                        label={individualItem.value}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Box>
            );
        case "checkbox":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">{item.question_text}</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            {item.answer_options.map((individualItem: any, index: number) => {
                                return (
                                    <FormControlLabel
                                        key={index + individualItem.id}
                                        value={individualItem.value}
                                        control={<Checkbox />}
                                        label={individualItem.value}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Box>
            );
        case "dropdown":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{item.question_text}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={dropdownOption}
                            label={item.question_text}
                            onChange={handleDropdownChange}
                        >
                            {item.answer_options.map((individualItem: any, index: number) => {
                                return (
                                    <MenuItem key={index + individualItem.id} value={individualItem.value}>
                                        {individualItem.value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
            );
        case "date":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={item.question_text}
                            value={item?.date}
                            onChange={(newDate) => {
                                handleDate(item.id, newDate);
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
            );
        case "time":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            label={item.question_text}
                            value={item?.time}
                            onChange={(newTime) => handleTime(item.id, newTime)}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
            );
        case "state":
            return (
                <Box sx={{ pt: 3 }} key={index + item.id}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{item.question_text}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={usaDropdownOption}
                            label={item.question_text}
                            onChange={(event) => handleUSADropdownChange(event)}
                        >
                            {CustomConstants.usaStates.map((individualItem, index) => {
                                return (
                                    <MenuItem key={index + individualItem.value} value={individualItem.question_text}>
                                        {individualItem.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
            );
        default:
            return <></>;
    }
};
