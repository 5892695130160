import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import CreateVsetReducer from "./slices/CreateVsetSlice";
import UnpackingReducer from "./slices/UnpackingSlice";
import UserReducer from "./slices/UserSlice";


export const store = configureStore({
    reducer: {
        counter: counterReducer,
        user: UserReducer,
        unpacking: UnpackingReducer,
        createVset: CreateVsetReducer
    },
});
