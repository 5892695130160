import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import CircularLoadingProgress from "components/CircularProgress";
import VSBox from "components/VSBox";

const LoginButton = ({ variant, color, hide }: any) => {
    const { loginWithRedirect } = useAuth0();
    return (
        <>
            <VSBox className={hide ? "visibility-hidden" : ""}>
                <Button
                    onClick={() => {
                        loginWithRedirect({ redirectUri: `${window.location.origin}/dashboard` });
                    }}
                    variant={variant ? variant : "contained"}
                    color={color ? color : "primary"}
                    size="small"
                    style={{ marginRight: "20px" }}
                    id="auth0-login-button"
                >
                    Login
                </Button>
            </VSBox>
            {hide && <CircularLoadingProgress />}
        </>
    );
};

export default LoginButton;
