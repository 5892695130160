// Material Imports
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import DatePicker from "@material-ui/lab/DatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import TimePicker from "@material-ui/lab/TimePicker";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VSBox from "components/VSBox";
import VSHelperText from "components/VSHelperText";
import VSTypography from "components/VSTypography";
import styles from "./styles";
// Constants
import { IconButton, InputAdornment, Radio, Tooltip } from "@mui/material";
import { CustomConstants } from "constants/custom.constants";
import styled from "styled-components";
import SpeechToTextButton from "./SpeechToText";

const ViewElementFormLabel = ({ hasError, label, isRequired, id, type = "form" }: any) => {
    return (
        <>
            {type === "form" ? (
                <FormLabel id={id} error={hasError}>
                    {label}
                    {isRequired ? "* " : ""}
                </FormLabel>
            ) : (
                <InputLabel id={id} error={hasError}>
                    {label}
                    {isRequired ? "* " : ""}
                </InputLabel>
            )}
        </>
    );
};

const ViewElementHelperText = ({ hasError, message }: any) => {
    if (!hasError) {
        return null;
    }
    return (
        <VSHelperText error variant="standard">
            {message}
        </VSHelperText>
    );
};

const WhiteBorderTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
        background: #f5f8fb;
        border-radius: 32px;

        &.Mui-focused fieldset {
            border-color: white;
        }
        &:hover fieldset {
            border-color: white;
        }
        & fieldset {
            border-color: white;
        }
    }
    &::placeholder {
        color: red;
    }
    & .MuiInputBase-input {
        background: #f5f8fb;
        &::placeholder {
            color: #325a6b;
            padding-top: 10px;
            padding-left: 10px;
        }
    }
    & .MuiTextField-root {
        background: #f5f8fb;
        & fieldset {
            border-color: white;
        }
    }
`;

// `UnpackingElements` is the function to show the user the Form Elements in Viewer Mode
// 2. item -> Represents the Form Item Object (Consisting of all properties)
export const UnpackingElements = ({
    item,
    handleValue,
    handleSpeechToText,
    isRequired,
    hasError,
    vignette_rank_category,
}: any) => {
    const errorMsg = "Required";
    const renderText = ({ hasError, isRequired, id, text, type = "form" }: any) => {
        const start = text.indexOf("[");
        const end = text.indexOf("]");
        return (
            <>
                {type === "form" ? (
                    <FormLabel
                        id={id}
                        error={hasError}
                        style={{
                            left: "auto",
                            right: "auto",
                        }}
                    >
                        {text.substring(0, start)}
                        {start != -1 && <b>{vignette_rank_category}</b>}

                        {text.substring(end + 1)}
                        {isRequired ? <span style={{ color: "red" }}>* </span> : ""}
                        {start != -1 && (
                            <Tooltip title="This is the description of the category.">
                                <InfoOutlinedIcon fontSize="small" style={{ marginBottom: "5px" }} />
                            </Tooltip>
                        )}
                    </FormLabel>
                ) : (
                    <InputLabel
                        id={id}
                        error={hasError}
                        // variant="body2"
                        style={{
                            left: "auto",
                            right: "auto",
                        }}
                    >
                        {text.substring(0, start)}
                        {start != -1 && <b>{vignette_rank_category}</b>}

                        {text.substring(end + 1)}
                        {isRequired ? <span style={{ color: "red" }}>* </span> : ""}
                        {start != -1 && (
                            <Tooltip title="This is the description of the category.">
                                <InfoOutlinedIcon fontSize="small" style={{ marginBottom: "5px" }} />
                            </Tooltip>
                        )}
                    </InputLabel>
                )}
            </>
        );
    };

    switch (item.answer_type) {
        case "text":
            return (
                <>
                    {renderText({
                        text: item.question_text,
                        id: "text-label",
                        hasError: hasError,
                        isRequired: isRequired,
                    })}

                    <Box sx={{ pt: 3 }} key={item.id}>
                        {/* <Grid display="flex" justifyContent="right" alignItems="center" py={1} mb={1} item xs={11}> */}
                        <Typography sx={styles.unpackingCompletionText}>
                            {item?.answer?.length ? item?.answer?.length : 0}/250 characters
                        </Typography>
                        {/* </Grid> */}
                        <WhiteBorderTextField
                            fullWidth
                            label=""
                            placeholder="Type your response here or tap on the mic to use voice..."
                            onChange={(event) => handleValue(event, item)}
                            value={item.answer}
                            required={isRequired}
                            error={!!hasError}
                            helperText={hasError && errorMsg}
                            FormHelperTextProps={{ variant: "standard" }}
                            multiline={true}
                            minRows={10}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            sx={styles.speechIcon}
                                        >
                                            {<SpeechToTextButton handleSpeechToText={handleSpeechToText} item={item} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                            // InputLabelProps={{ shrink: false }}
                            // InputProps={{
                            //     startAdonment: (
                            //         <InputAdornment position="start">
                            //             <KeyboardVoiceIcon color="red" />
                            //         </InputAdornment>
                            //     )
                            // }}
                        >
                            <KeyboardVoiceIcon />
                        </WhiteBorderTextField>
                    </Box>
                </>
            );
        case "textarea":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <TextField
                        fullWidth
                        label={item.question_text}
                        multiline
                        rows={3}
                        value={item.answer}
                        onChange={(event) => handleValue(event, item)}
                        required={isRequired}
                        error={!!hasError}
                        helperText={hasError && errorMsg}
                        FormHelperTextProps={{ variant: "standard" }}
                    />
                </Box>
            );
        case "header_info":
            return (
                <VSBox sx={{ pt: 3 }} key={item.id}>
                    <VSTypography>{item.question_text}</VSTypography>
                </VSBox>
            );
        case "number":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <TextField
                        fullWidth
                        label={item.question_text}
                        type="number"
                        value={item.answer}
                        onChange={(event) => handleValue(event, item)}
                        required={isRequired}
                        error={!!hasError}
                        helperText={hasError && errorMsg}
                        FormHelperTextProps={{ variant: "standard" }}
                    />
                </Box>
            );
        case "radio":
            return (
                // <Box sx={{ pt: 3 }} key={item.id}>
                //     <FormControl>
                //         <ViewElementFormLabel
                //             id="demo-radio-buttons-group-label"
                //             hasError={hasError}
                //             label={item.question_text}
                //             isRequired={isRequired}
                //         />
                //         <RadioGroup
                //             aria-labelledby="demo-radio-buttons-group-label"
                //             defaultValue=""
                //             name="radio-buttons-group"
                //             onChange={(event) => handleValue(event, item)}
                //         >
                //             {item.answer_options.map((individualItem, index) => {
                //                 return (
                //                     <FormControlLabel
                //                         key={index + individualItem.id}
                //                         value={individualItem.value}
                //                         control={<Radio />}
                //                         label={individualItem.value}
                //                         checked={individualItem.value === item.answer}
                //                     />
                //                 );
                //             })}
                //         </RadioGroup>
                //         <ViewElementHelperText hasError={hasError} message={errorMsg} />
                //     </FormControl>
                // </Box>
                <>
                    {renderText({
                        text: item.question_text,
                        id: "demo-radio-buttons-group-label",
                        hasError: hasError,
                        isRequired: isRequired,
                    })}
                    <Box sx={{ pt: 3 }} key={item.id}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="radio-buttons-group"
                                onChange={(event) => handleValue(event, item)}
                            >
                                {item.answer_options.map((individualItem: { value: unknown; id: any }, index: any) => {
                                    return (
                                        <div
                                            style={{
                                                border: "1px solid #f5f8fb",
                                                background:
                                                    individualItem.value === item.answer ? "#29B1E2" : "#f5f8fb",
                                                color: individualItem.value === item.answer ? "white" : "black",
                                                borderRadius: "32px",
                                                margin: "10px",
                                                paddingLeft: "2%",
                                                minWidth: "60vw",
                                            }}
                                        >
                                            <FormControlLabel
                                                key={index + individualItem.id}
                                                value={individualItem.value}
                                                control={
                                                    <Radio
                                                        checkedIcon={<CheckCircleIcon style={{ color: "white" }} />}
                                                    />
                                                }
                                                label={individualItem.value}
                                                checked={individualItem.value === item.answer}
                                            />
                                        </div>
                                    );
                                })}
                            </RadioGroup>
                            <ViewElementHelperText hasError={hasError} message={errorMsg} />
                        </FormControl>
                    </Box>
                </>
            );
        case "checkbox":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <FormControl>
                        <ViewElementFormLabel
                            id="demo-radio-checkbox-buttons-group-label"
                            hasError={hasError}
                            label={item.question_text}
                            isRequired={isRequired}
                        />
                        <RadioGroup
                            aria-labelledby="demo-radio-checkbox-buttons-group-label"
                            defaultValue={null}
                            name="radio-buttons-group"
                        >
                            {item.answer_options.map((individualItem: { id: any; value: unknown }, index: any) => {
                                return (
                                    <FormControlLabel
                                        key={index + individualItem.id}
                                        value={individualItem.value}
                                        control={<Checkbox onChange={(event) => handleValue(event, item)} />}
                                        label={individualItem.value}
                                        checked={(item.answer || []).includes(individualItem.value)}
                                    />
                                );
                            })}
                        </RadioGroup>
                        <ViewElementHelperText hasError={hasError} message={errorMsg} />
                    </FormControl>
                </Box>
            );
        case "dropdown":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <FormControl fullWidth error={hasError}>
                        <InputLabel id="demo-simple-select-label">
                            {item.question_text} {isRequired ? "*" : ""}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.answer}
                            defaultValue=""
                            label={item.question_text}
                            onChange={(event) => handleValue(event, item)}
                        >
                            {item.answer_options.map(
                                (individualItem: { id: any; value: any | null | undefined }, index: any) => (
                                    <MenuItem
                                        key={index + individualItem.id}
                                        // value={individualItem.value}
                                    >
                                        {individualItem.value}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                        <ViewElementHelperText hasError={hasError} message={errorMsg} />
                    </FormControl>
                </Box>
            );
        case "date":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={`${item.question_text},${item.answer || ""}`}
                            value={item?.answer || null}
                            onChange={(newDate) => handleValue(newDate, item)}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        fullWidth
                                        // {...params}
                                        required={isRequired}
                                        error={!!hasError}
                                        helperText={hasError && errorMsg}
                                        FormHelperTextProps={{ variant: "standard" }}
                                    />
                                );
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            );
        case "time":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            label={item.question_text}
                            value={item?.answer || null}
                            onChange={(newTime) => handleValue(newTime, item)}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    // {...params}
                                    required={isRequired}
                                    error={!!hasError}
                                    helperText={hasError && errorMsg}
                                    FormHelperTextProps={{ variant: "standard" }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
            );
        case "state":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <FormControl fullWidth error={hasError}>
                        <InputLabel id="demo-simple-state-select-label">
                            {item.question_text} {isRequired ? "*" : ""}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-state-select-label"
                            id="demo-simple-state-select"
                            value={item.answer}
                            label={item.question_text}
                            onChange={(event) => handleValue(event, item)}
                            defaultValue=""
                        >
                            {CustomConstants.usaStates.map((individualItem, index) => {
                                return (
                                    <MenuItem key={index + individualItem.value} value={individualItem.value}>
                                        {individualItem.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <ViewElementHelperText hasError={hasError} message={errorMsg} />
                    </FormControl>
                </Box>
            );
        case "section":
            return (
                <Box sx={{ pt: 3, pb: 3 }} key={item.id}>
                    <Typography variant="h5" component="div" gutterBottom>
                        {item.question_text}
                    </Typography>
                    {/* <hr size="5" /> */}
                </Box>
            );
        default:
            return <></>;
    }
};
