import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { MinimumUnpackingCountSelector, UnpackedVignetteCountSelector } from "app/slices/UnpackingSlice";
import VSBorderLinearProgress from "components/VSBorderLinearProgress";
import VSCard from "components/VSCard/VSCard";
import { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import AllCategoryVignettes from "./allCategoryVignettes";
import SelectedCategoryVignettes from "./selectedCategoryVignettes";
import styles from "./styles";

export default function ParkingLotVignettes({
    data,
    setVignetteSelected,
    openUnpackingDialog,
    availableVignettes = 0,
}: any) {
    const [selectedDomain, setSelectedDomain] = useState("All");
    const [flaggedVignettes, setFlaggedVignettes] = useState([]);

    const [vignetteCount, setVignetteCount] = useState({
        flaggedVignettes: 0,
        totalVignettes: 0,
    });

    const navigate = useNavigate();
    const unpackedVignetteCount = useSelector(UnpackedVignetteCountSelector);
    const minimumUnpackingCount = useSelector(MinimumUnpackingCountSelector);

    useEffect(() => {
        setFlaggedVignettes(data);
        setVignetteSelected(data[0].vignettes[0]);
        let flaggedVignettes = 0;
        let totalVignettes = 0;

        data?.map((eachDomain: { totalVignettesCount: number; flaggedVignettesCount: number }) => {
            totalVignettes = totalVignettes + eachDomain.totalVignettesCount;
            flaggedVignettes = flaggedVignettes + eachDomain.flaggedVignettesCount;
        });
        const testVignetteCount = {
            flaggedVignettes: flaggedVignettes,
            totalVignettes: totalVignettes,
        };
        setVignetteCount(testVignetteCount);
    }, []);

    const chooseSelectedDomain = (domain: SetStateAction<string>) => {
        setSelectedDomain(domain);
    };

    const checkFinishedUnpackingStatus = () => {
        if (availableVignettes == 0) {
            return false;
        }
        if (availableVignettes == unpackedVignetteCount) {
            return false;
        }
        if (unpackedVignetteCount >= minimumUnpackingCount) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <VSCard sx={styles.outerCard}>
            <Grid container spacing={1} sx={{ width: "100%" }}>
                <Grid item xs={2}>
                    <Typography sx={styles.categoryTitle}>Categories</Typography>
                    <Typography
                        sx={styles.categoryList}
                        style={{
                            fontWeight: selectedDomain == "All" ? "800" : "400",
                            color: selectedDomain == "All" ? "#29ABE2" : "",
                            fontStyle: selectedDomain == "All" ? "bold" : "",
                        }}
                        onClick={() => chooseSelectedDomain("All")}
                    >
                        All ({vignetteCount["flaggedVignettes"]}/{vignetteCount["totalVignettes"]})
                    </Typography>

                    {flaggedVignettes?.map((eachDomain) => {
                        return (
                            <Typography
                                sx={styles.categoryList}
                                style={{
                                    fontWeight: selectedDomain == eachDomain["domain_name"] ? "800" : "400",
                                    color: selectedDomain == eachDomain["domain_name"] ? "#29ABE2" : "",
                                    fontStyle: selectedDomain == eachDomain["domain_name"] ? "bold" : "",
                                }}
                                onClick={() => chooseSelectedDomain(eachDomain["domain_name"])}
                            >
                                {eachDomain["domain_name"]} ({eachDomain["flaggedVignettesCount"]}/
                                {eachDomain["totalVignettesCount"]})
                            </Typography>
                        );
                    })}

                    <Box style={{ marginTop: "67vh" }}>
                        <VSBorderLinearProgress
                            sx={styles.linearProgress}
                            variant="determinate"
                            value={
                                (unpackedVignetteCount / minimumUnpackingCount) * 100 <= 100
                                    ? (unpackedVignetteCount / minimumUnpackingCount) * 100
                                    : 100
                            }
                        />
                        <Typography
                            variant="body2"
                            sx={styles.completedText}
                            style={{
                                color: !checkFinishedUnpackingStatus() ? "##009245" : "#929292",
                            }}
                        >
                            {unpackedVignetteCount} / {minimumUnpackingCount} completed
                        </Typography>
                        <Box style={{ marginTop: "15px" }}>
                            <Button variant="contained" sx={styles.button} onClick={() => navigate('/dashboard')}>
                                Finish Cluster
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {selectedDomain === "All" && (
                    <AllCategoryVignettes
                        flaggedVignettes={flaggedVignettes}
                        chooseSelectedDomain={chooseSelectedDomain}
                        openUnpackingDialog={openUnpackingDialog}
                    />
                )}
                {selectedDomain != "All" && (
                    <SelectedCategoryVignettes
                        flaggedVignettes={flaggedVignettes}
                        selectedDomain={selectedDomain}
                        openUnpackingDialog={openUnpackingDialog}
                    />
                )}
            </Grid>
        </VSCard>
    );
}
