import { InputAdornment, TextField } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import styles from './styles';

export type PropsType = {
    value: string,
    placeholder?: string,
    style?: CSSProperties,
    onSearch: (searchKey: string) => void,
}

const Search = ({ value, placeholder = "", style ={}, onSearch }: PropsType) => {
    const [searchKey, setSearchKey] = useState<string>(value)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value)
    }

    const handleClearSearchKey = () => {
        setSearchKey("")
    }

    useEffect(() => {
        onSearch(searchKey)
    }, [searchKey])

    return (
        <TextField
            size="small"
            variant="outlined"
            placeholder={placeholder}
            sx={[ style, styles.searchBox ]}
            value={searchKey}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" sx={{ml: '15px'}}>
                        <SearchIcon style={styles.searchIcon} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment
                        position="end"
                        onClick={handleClearSearchKey}
                        sx={{mr: '15px'}}
                    >
                        <CloseIcon style={styles.closeIcon} />
                    </InputAdornment>
                ),
                style: { padding: '4px'}
            }}
        />
    )
}

export default Search