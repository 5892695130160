import MonitorIcon from "assets/icons/monitor.svg";
import RouteIcon from "assets/icons/route.svg";
import StackedIcon from "assets/icons/stacked.svg";
import ThunderIcon from "assets/icons/thunder.svg";
import React, { RefObject } from "react";
import "./Features.css";

interface Props {
    featuresRef: RefObject<HTMLDivElement>;
}

const Features: React.FC<Props> = ({ featuresRef }) => {
    return (
        <div>
            <div className="featureContainer" id="scrollFeature" ref={featuresRef}>
                {/* Monitor */}
                <div className="featureItem">
                    <img src={MonitorIcon} alt="Monitor" />
                    <div>Out-of-the-box dashboards, activities, and more</div>
                </div>

                {/* Route */}
                <div className="featureItem">
                    <div>
                        <img src={RouteIcon} alt="Route" />
                    </div>
                    <div>Instantly deploy activities with a single click</div>
                </div>

                {/* Thunder */}
                <div className="featureItem">
                    <div>
                        <img src={ThunderIcon} alt="Thunder" />
                    </div>
                    <div>User-friendly interface for seamless navigation</div>
                </div>

                {/* Stacked */}
                <div className="featureItem">
                    <div>
                        <img src={StackedIcon} alt="Stacked" />
                    </div>
                    <div>Instant data analysis and visualization</div>
                </div>
            </div>
        </div>
    );
};

export default Features;
