/* eslint-disable no-unused-expressions */
import { styled } from "@mui/material/styles";

// import arrayMove from "array-move";
import { Button } from "@mui/material";
const VSDisableButton = styled(Button)(({ theme }) => ({
    color: "#94A8B0",
    textTransform: "capitalize",
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: "14px",
    padding: "12px 28px 12px 28px",
    backgroundColor: "#F5F8FB",
    margin: "0 20px 0 0",
    "&:hover": {
        backgroundColor: "#F5F8FB",
        color: "#94A8B0",
        borderRadius: "8px",
        border: "2px solid #94A8B0",
    },
    borderRadius: "8px",
    border: "2px solid #94A8B0",
}));

export default VSDisableButton;
