const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        justifyContent: 'space-between',
        alighItems: 'center',
        backgroundColor: '#fff',
        padding: '15px',
        borderRadius: '10px',
        marginBottom: '1.2rem',
    }
}

export default styles