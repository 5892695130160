import seedFundingImage from '../../../assets/images/seed-funding.png';
import './NSF.css';

const NSF:React.FC = () => {

  return (
    <div className='NSF'>
      <div className='NSFContainer'>
        <div className='NSFContainer__left'>
          <h1>VSorts™ Awarded Prestigious National Science Foundation SBIR Startup Funding!</h1>
          <p>VSorts™ is proud to receive the National Science Foundation&apos;s Small Business Innovation Research (SBIR) Phase 1 grant. This recognition underscores our patent pending innovation and commitment to enhancing domain-specific social and cultural awareness across various sectors. Our team is dedicated to reducing biases and social risks and promoting inclusive language, practices, and organizational alignment.</p>
          <a href="https://seedfund.nsf.gov/awardees/history/details/?company=culturally-responsive-solutions-llc" target="_blank" rel="noreferrer">
            <button className='navbarActionButton navbarActionButtons-getStarted'>Learn more</button>
          </a>
        </div>
        <img src={seedFundingImage} alt='seed Funding' />
      </div>
    </div>
  )
}

export default NSF;