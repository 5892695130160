
import { Box } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { uid } from 'react-uid';
import styles from "./styles";

interface tab {
    name: string;
    url: string;
}

type PropsType = {
    activeTab: tab,
    tabs: tab[],
    onTabSelect: (tab: tab) => void,
    setActiveTab: Dispatch<SetStateAction<tab>>
}

const Tabs = ({ tabs, activeTab, setActiveTab, onTabSelect }: PropsType) => {
    const handleSelectTab = (tab: tab) => {
        setActiveTab(tab)
        onTabSelect(tab)
    }

    return (
        <Box sx={styles.tabs}>
            {tabs.map((tab, idx) => (
                <Box
                    key={uid(tab)}
                    sx={[
                        styles.tab, 
                        idx < tabs.length - 1 && styles.tabWithMargin,
                        tab.name === activeTab.name && styles.activeTab
                    ]}
                    onClick={() => handleSelectTab(tab)}
                >
                    
                    {tab.name}
                </Box>
            ))}
        </Box>
    )
}

export default Tabs;