const styles: any = {
    outerCard: {
        minWidth: "80vw",
        maxWidth: "80vw",
        left: "auto",
        right: "auto",
        backgroundColor: "#ffffff",
        padding: "15px",
        borderRadius: "15px",
    },
    categoryTitle: {
        fontFamily: "Montserrat",
        fontStyle: "bold",
        fontWeight: "800",
        fontSize: "18px",
    },
    categoryList: {
        fontFamily: "Montserrat",
        fontWeight: "400",
        fontSize: "14px",
        marginTop: "24px",
        color: "#29ABE2",
        fontStyle: "bold",
        cursor: "pointer",
    },
    completedText: {
        color: "#929292",
        lineHeight: "24px",
        fontFamily: "Montserrat",
        fontWeight: "400",
        fontSize: "14px",
        marginTop: "10px",
    },
    button: {
        borderColor: "#C0C0C0",
        backgroundColor: "#C0C0C0",
        color: "white",
        textTransform: "none",
        fontFamily: "Montserrat",
        padding: "10px, 24px, 10px, 24px",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        borderRadius: "10px",
        width: "150px",
        height: "42px",
    },
    allCategoryVignetteCard: {
        backgroundColor: "#F5F8FB",
        width: "100%",
        minHeight: "102vh",
        maxHeight: "102vh",
        overflow: "auto",
        padding: "20px",
    },
    domainHeading: {
        fontFamily: "Montserrat",
        fontStyle: "bold",
        fontWeight: "800",
        fontSize: "14px",
        lineHeight: "19.12px",
        marginBottom: "10px",
        cursor: "pointer",
    },
    allVignetteFlexBox: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    vignetteTextDisplayGrid: {
        boxShadow: "0 0 10px rgba(41, 171, 226, 0.5)",
        margin: "3% 3% 3% 0",
        backgroundColor: "#29ABE2",
        opacity: 1,
        borderRadius: "8px",
        minHeight: "80px",
        width: "100%",
        cursor: "pointer",
    },
    bluePortion: {
        backgroundColor: "#fff",
        marginLeft: "10px",
        width: "100%",
        minHeight: "80px",
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    textGrid: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
    },
    vignetteText: {
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "17px",
        marginLeft: "10px",
    },
    dragIconGrid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    congratsCard: {
        backgroundColor: "#F5F8FB",
        width: "100%",
        padding: "20px",
    },
    congratsText: {
        fontFamily: "Montserrat",
        fontWeight: "900",
        fontSize: "16px",
        lineHeight: "26px",
    },
    explanationCard: {
        backgroundColor: "#F5F8FB",
        width: "100%",
        minHeight: "85%",
        maxHeight: "85%",
        padding: "20px",
        marginTop: "4vh",
    },
    explanationHeadingText: {
        fontWeight: "900",
        fontSize: "16px",
        lineHeight: "26px",
        color: "#29ABE2",
        fontFamily: "Montserrat",
        fontStyle: "bold",
    },
    explanationText: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "24px",
        fontFamily: "Montserrat",
        fontStyle: "italic",
        marginTop: "2vh",
    },
    explanationOuterBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "60vh",
    },
    explanationInnerBox: {
        minWidth: "8vw",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    iconButton: {
        borderRadius: "50%",
        height: "50px",
        minWidth: "50px",
        maxWidth: "50px",
        borderColor: "#C0C0C0",
    },
    unpackButton: {
        borderColor: "#C0C0C0",
        color: "#C0C0C0",
        textTransform: "none",
        fontFamily: "Montserrat",
        padding: "10px, 24px, 10px, 24px",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "19px",
        borderRadius: "10px",
        width: "150px",
        height: "42px",
        gap: "8px",
    },
    eachCategory: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "19px",
        color: "#29ABE2",
        fontFamily: "Montserrat",
        marginBottom: "-5px",
    },
    linearProgress: {
        marginRight: "10px",
    },
};

export default styles;
