import { Box, Typography } from "@mui/material";
import React from "react";

const VSUnpackingHeader = () => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "24px",
                        lineHeight: "33px",
                        display: "flex",
                        alignItems: "center",
                        color: "#111111",
                    }}
                >
                    BIPOC: Teaching <span style={{ fontWeight: 500 }}> | Unpacking </span>
                </Typography>
                <Typography
                    sx={{
                        fontSize: "14px",
                        lineHeight: "17px",
                        textAlign: "center",
                        color: "#111111",
                        marginY: "1.5rem",
                    }}
                >
                    <strong>Directions:</strong>
                    Drag each vignette shown below to the right into the empty box that
                    corresponds with the culturally proficient behavior/interaction
                    displayed in the empty box.
                </Typography>
            </Box>
        </>
    );
};

export default VSUnpackingHeader;