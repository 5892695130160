// FormContainers -> Used to represent various Survey Containers (Pre-Survey, Post-Survey and Final Feedback)
export const FormContainers = Object.freeze({
    preSurvey: "pre_survey_container",
    postSurvey: "post_survey_container",
    finalFeedback: "final_feedback_container",
});

// FormQuestions -> Used to represent various Survey (Pre-Survey, Post-Survey and Final Feedback) Questions
export const FormQuestions = Object.freeze({
    preSurvey: "pre_survey_questions",
    postSurvey: "post_survey_questions",
    finalFeedback: "final_feedback_questions",
});

export const FormTypeAndContainerQuestionTableMapper = Object.freeze({
    presurvey: "pre_survey_questions",
    postsurvey: "post_survey_questions",
    finalfeedback: "final_feedback_questions",
    vsorts: "vignette",
});

export const FormTypes = Object.freeze({
    presurvey: "presurvey",
    postsurvey: "postsurvey",
    finalfeedback: "finalfeedback",
    vsorts: "vsorts",
    unpacking: "unpacking",
    scoring: "scoring",
});

export const FormTypeLabels: any = Object.freeze({
    presurvey: "Survey",
    postsurvey: "Survey",
    finalfeedback: "Lagniappe Page",
    vsorts: "VSorts",
    unpacking: "VSets",
    scoring: "VSets"
});

export const VSetParameterTypes = Object.freeze({
    offset: "offset",
    minUnpacking: "minimum unpacking",
    maxUnpacking: "maximum unpacking",
    tally: "tally",
});

export const ClusterFormElementsType = Object.freeze([
    {
        label: "VSets",
        value: "vsorts",
    },
    {
        label: "Pre Survey",
        value: "presurvey",
    },
    {
        label: "Post Survey",
        value: "postsurvey",
    },
    {
        label: "Lagniappe",
        value: "finalfeedback",
    },
    {
        label: "Video",
        value: "video",
    },
    {
        label: "Unpacking",
        value: "unpacking",
    },
]);


export const ParameterTypeElements = Object.freeze([
    {
        label: "offset",
        value: "offset",
    },
    {
        label: 'minimum unpacking',
        value:'minimum unpacking'
    },
    {
        label: 'maximum unpacking',
        value:'maximum unpacking'
    }
]);
