import { FormTypes } from "constants/enum.constants";
import { useFormData } from "hooks/surveyFormData";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useOutletContext } from "react-router-dom";

const CreateSurvey = forwardRef(function CreateSurvey() {
    const { childRef }: any = useOutletContext();

    const [data, setData] = useState<any[]>([]);

    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    const id = searchParams.get("id");
    const isCreate = searchParams.get("isCreate");

    const onSurveyQuestionsDataFetchSuccess = (data: any) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        const updatedData = data.map((section: any) => ({
            ...section,
            answer_type: "section",
        }));
        setData(updatedData);
        //setIsData(true);
    };

    const onSurveyQuestionsDataFetchError = (error: any) => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        // 
    };


    // 1. isFetched -> Represents that the data has finished fetching (In Background)
    // 2. isFetching -> Represents data being fetched (In background)
    // 3. isLoading -> Represents data being fetched (In Foreground)
    // 4. isError -> Represents if the request errored out
    // 5. data -> Represents the data from the API Response
    // 6. error -> Represents the error message of the request erroring out
    // 7. refetch -> Represents the refetch function to initiate a network request
    const {
        isFetched,
        isFetching: isSurveyQuestionsDataFetching,
        isLoading: isSurveyQuestionsDataLoading,
        isError: isSurveyQuestionsDataError,
        data: allSurveyQuestionsData,
        error,
        refetch: refetchSurveyQuestionsData,
    } = useFormData({
        surveyID: id,
        formType: FormTypes.presurvey,
        isEnabled: !isCreate,
        onSuccess: onSurveyQuestionsDataFetchSuccess,
        onError: onSurveyQuestionsDataFetchError,
    });

    

    // called when save button is clicked on the parent component
    useImperativeHandle(childRef, () => {

        return {
            save() {
                console.log("CreateSurvey save")
            },

            addSection() {
                console.log("CreateSurvey addSection")
            }
        }

    });
    return <div>CreateSurvey</div>;
});

export default CreateSurvey;
