import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import './CollapsiblePanel.css';

import { Collapse } from '@mui/material';

function CollapsiblePanel({ header, content } : any) {
  const [isCollapse, setIsCollapse] = useState(false);
  return (
    <div className={`collapsiblePanel ${isCollapse ? 'open' : ''}`}>
      <div className='collapsiblePanel__header'>
        <div
          className={`collapsiblePanel__headerleft ${isCollapse ? 'active' : ''}`}
          onClick={() => setIsCollapse(!isCollapse)}
          role='button'
        >
          <div className='collapsiblePanel__headerleft-Icon'>
            {isCollapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowDownIcon />}
          </div>
          <p>{header}</p>
        </div>
      </div>
      <div className='collapsiblePanel__content'>
        <Collapse in={isCollapse} timeout={1000}>
          {content}
        </Collapse>
      </div>
    </div>
  );
}

export default CollapsiblePanel;
