import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import { useState } from "react";

import { Tooltip } from "@mui/material";
// import VSColorButton from "components/VSColorButton";
import VSDisableButton from "components/VSDisableButton";

/* eslint-disable no-unused-expressions */
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

// import arrayMove from "array-move";
const VSColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    textTransform: "capitalize",
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: "14px",
    padding: "12px 28px 12px 28px",
    backgroundColor: "#29ABE2",
    margin: "0 20px 0 0",
    "&:hover": {
        backgroundColor: "rgba(41, 177, 226, 0.8)",
        // color: "#94A8B0",
        // borderRadius: "8px",
        // border: "2px solid #94A8B0",
    },
    borderRadius: "8px",
    border: "2px",

    // color: theme.palette.getContrastText(purple[500]),
    // backgroundColor: "#29ABE2",
    // margin: "0 20px 0 0",
    // "&:hover": {
    //     backgroundColor: "rgba(41, 177, 226, 0.8)",
    // },
    // borderRadius: "8px",
    // borderWidth: "2px",
}));

const UnpackingStepperProgress = ({ totalSteps, order, handleNext, handleBack, nextText, loading }: any) => {
    const [activeStep, setActiveStep] = useState(order);
    const [readyToClick, setReadyToClick] = useState(true);
    const handleNextClick = () => {
        if (handleNext) {
            handleNext(activeStep + 1);
        } else {
            setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
        }
    };

    const handleBackClick = () => {
        if (handleBack) {
            handleBack(activeStep - 1);
        } else {
            setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
        }
    };

    return (
        <MobileStepper
            variant="dots"
            steps={totalSteps}
            position="static"
            activeStep={activeStep}
            sx={{ flexGrow: 1 }}
            nextButton={
                readyToClick ? (
                    <VSColorButton onClick={handleNext} variant="contained" disabled={loading}>
                        {nextText || "Next"}
                    </VSColorButton>
                ) : (
                    <Tooltip
                        placement="bottom-end"
                        title={
                            "You cannot submit your response until a minimum of " + "" + " vignette(s) are unpacked."
                        }
                    >
                        <VSDisableButton variant="outlined">{nextText || "Next"}</VSDisableButton>
                    </Tooltip>
                )
                // <VSButton variant="contained" onClick={handleNextClick} disabled={loading} loading={loading}>
                //     {nextText || "Next"}
                //     <KeyboardArrowRight />
                // </VSButton>
            }
            backButton={
                // <Button variant="contained" onClick={handleBackClick} disabled={activeStep === 0 || loading}>
                //     <KeyboardArrowLeft />
                //     Back
                // </Button>
                <VSDisableButton variant="outlined" disabled={activeStep === 0 || loading} onClick={handleBackClick}>
                    Back
                </VSDisableButton>
            }
            style={{
                background: "white",
            }}
        />
    );
};

export default UnpackingStepperProgress;
